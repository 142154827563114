import { Input } from "antd";
import { lightenDarkenColor } from "../../utils";

/**
 * Customized modal input
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function ModalInput(props) {
  const placeholder = props.placeholder;
  const color = props.color;
  const onChange = props.onChange;
  const isFilled = props.isFilled;
  const value = props.value;
  const onPressEnter = props.onPressEnter;
  const maxLength = props.maxLength

  return (
    <Input
      placeholder={placeholder}
      onPressEnter={onPressEnter}
      style={{
        border: `1px solid ${lightenDarkenColor(color, 120)}`,
        color: color,
        fontWeight: 500,
        transition: "background-color 0.6s linear",
        backgroundColor: isFilled ? "white" : lightenDarkenColor(color, 140),
      }}
      allowClear
      onChange={(name) => onChange(name.target.value)}
      value={value}
      maxLength={maxLength}
    ></Input>
  );
}

export { ModalInput };
