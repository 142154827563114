import { Button, Col, List, message, Row, Spin } from "antd";
import { useState } from "react";
import { SetAsRead } from "../../../api/notifications";
import { colors } from "../../../colors";
import { lightenDarkenColor } from "../../../utils";

const getTimeDifference = (notification) => {
  var currentdate = new Date();
  let diffMs = Date.parse(currentdate) - Date.parse(notification.created);
  var diffDays = Math.floor(diffMs / 86400000); // days
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  if (diffDays > 0) diffDays = diffDays + (diffHrs > 12 ? 1 : 0);
  if (diffHrs > 0) diffHrs = diffHrs + (diffMins > 30 ? 1 : 0);

  let result =
    diffDays > 0
      ? `${diffDays} day${diffDays > 1 ? "s" : ""} ago`
      : diffHrs > 0
      ? `${diffHrs} hour${diffHrs > 1 ? "s" : ""} ago`
      : diffMins > 0
      ? `${diffMins} minute${diffMins > 1 ? "s" : ""} ago`
      : "now";
  return result;
};

/**
 * Notification list that appears on dropdown
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function NotificationList(props) {
  const maxCount = props.maxCount;
  const notifications = props.notifications;
  const setMaxCount = props.setMaxCount;
  const setNotifications = props.setNotifications;
  const color = props.color;

  const [loading, setLoading] = useState(false);

  const onLoadMore = () => {
    setLoading(true);
    setMaxCount(maxCount + 5);
    setLoading(false);
  };

  const markAllAsRead = () => {
    let unreadNotifications = notifications.filter(
      (notification) => notification.read == false
    );
    let unreadNotificationsIds = unreadNotifications.map(function (
      notification
    ) {
      return notification.id;
    });
    if (unreadNotifications.length == 0) {
      message.destroy();
      message.warn("No notifications to mark!");
    } else {
      message.destroy();
      message.loading("Marking all messages...", 10000);
      SetAsRead(unreadNotificationsIds).then(() => {
        setNotifications(
          notifications.map(function (element) {
            element.read = true;
            return element;
          })
        );
        message.destroy();
        message.success("All messages are marked as read!");
      });
    }
  };

  const loadMore = (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 44,
        lineHeight: "32px",
      }}
    >
      <Button
        style={{
          marginBottom: 12,
          color: color,
        }}
        type="link"
        onClick={markAllAsRead}
      >
        Mark all as read
      </Button>
    </div>
  );

  const markAsRead = (notification, index) => {
    let newNotifications = JSON.parse(JSON.stringify(notifications));
    notification.read = true;
    newNotifications[index] = notification;
    setNotifications(newNotifications);
    message.destroy();
    message.loading("Marking notification as read...", 10000);
    SetAsRead([notification.id]).then(() => {
      message.destroy();
      message.success("Notification marked as read!");
    });
  };

  return (
    <List
      // className="demo-loadmore-list"
      style={{
        maxWidth: 360,
        width: "200%",
        fontFamily: "Poppins",
        maxHeight: "100%",
        // overflowY: "scroll",
        backgroundColor: "white",
        marginTop: 10,
        borderRadius: 12,
        boxShadow: "0px 2px 16px rgba(156, 181, 196, 0.15)",
        marginRight: "220px",
      }}
      loading={loading}
      loadMore={loadMore}
      locale={
        <Row justify="center">
          <Spin tip="Loading..." size="large" style={{ paddingTop: "5vh" }} />
        </Row>
      }
      dataSource={notifications}
      renderItem={(notification, index) =>
        index < maxCount ? (
          <List.Item
            // actions={
            //   !notification.read
            //     ? [
            //         <a
            //           href="javascript:void(0);"
            //           key="list-loadmore-edit"
            //           onClick={() => markAsRead(notification, index)}
            //         >
            //           Mark As Read
            //         </a>,
            //       ]
            //     : []
            // }
            onClick={() => {
              if (!notification.read) markAsRead(notification, index);
            }}
            style={{
              backgroundColor: notification.read
                ? "transparent"
                : lightenDarkenColor(color, 160),
              borderRadius: index == 0 ? "8px 8px 0px 0px" : "0px",
              cursor: !notification.read ? "pointer" : "default",
            }}
          >
            <List.Item.Meta
              avatar={
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <circle
                    cx="19.9999"
                    cy="20.0001"
                    r="19.9999"
                    fill={lightenDarkenColor(color, 140)}
                  />{" "}
                  <svg
                    x={"28%"}
                    y={"28%"}
                    width="19"
                    height="17"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3337 9.66667H15.667V4.03167L9.06033 9.94833L2.33366 4.01333V13.8333H10.667V15.5H1.50033C1.27931 15.5 1.06735 15.4122 0.91107 15.2559C0.75479 15.0996 0.666992 14.8877 0.666992 14.6667V1.33333C0.666992 1.11232 0.75479 0.900358 0.91107 0.744078C1.06735 0.587797 1.27931 0.5 1.50033 0.5H16.5003C16.7213 0.5 16.9333 0.587797 17.0896 0.744078C17.2459 0.900358 17.3337 1.11232 17.3337 1.33333V9.66667ZM2.75949 2.16667L9.05116 7.71833L15.252 2.16667H2.75949ZM14.8337 16.3333L11.887 13.3867L13.0662 12.2083L14.8337 13.9767L17.7803 11.03L18.9587 12.2083L14.8337 16.3333Z"
                      fill={color}
                    />
                  </svg>
                </svg>
              }
              style={{ padding: "2% 4% 0% 4%" }}
              title={
                <div
                  style={
                    !notification.read
                      ? {
                          color: colors["BLACK"],
                          fontWeight: 500,
                          fontSize: "14px",
                        }
                      : { color: color, fontWeight: 500 }
                  }
                >
                  {notification.message}
                </div>
              }
              description={
                <Row>
                  <Col span={18}>
                    <Row justify="start">
                      <div
                        style={{
                          color: colors["GRAY"],
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {getTimeDifference(notification)}
                      </div>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {!notification.read ? (
                      <Row justify="end">
                        <div
                          style={{
                            borderRadius: "50%",
                            height: 12,
                            width: 12,
                            backgroundColor: "#EB5757",
                          }}
                        ></div>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              }
            />
          </List.Item>
        ) : (
          <></>
        )
      }
    ></List>
  );
}

export { NotificationList };
