import { axiosURL } from "../config/axios";

const FetchUserInfo = (email, image) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/userProfile/userInfo", {
        params: { email: email, image: image, fetchType: "GET" },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err.message);
        resolve(err);
      });
  });
};

const EditUser = (user) => {
  let form = user;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/userProfile/userInfo/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const DeleteUser = (id, targetId) => {
  let form = {};
  form["userId"] = id;
  form["targetId"] = targetId;
  return new Promise((resolve, reject) => {
    axiosURL
      .delete("/userProfile/userInfo", { data: form })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const FetchAllUsers = (email) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/userProfile/userInfo", {
        params: { email: email, fetchType: "LIST" },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const SignUp = (email, name, phoneNumber, companyId, picture) => {
  let form = new FormData();
  form.append("email", email);
  form.append("name", name);
  form.append("phone", phoneNumber);
  form.append("companyId", companyId);
  form.append("image", picture);
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/userProfile/userInfo/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const FetchCompanies = () => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/userProfile/company")
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

export {
  FetchUserInfo,
  FetchCompanies,
  SignUp,
  FetchAllUsers,
  EditUser,
  DeleteUser,
};
