import { MinusCircleFilled } from "@ant-design/icons";
import { Collapse, Dropdown, Input, List, Menu, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DeleteUser, EditUser, FetchCompanies } from "../../api/user";
import { colors, themeColors } from "../../colors";
import { generateCircularIcon, generateIcon } from "../../iconGenerator";
import { selectTheme } from "../../store/filterSlice";
import { setUserList } from "../../store/userSlice";
import { CustomModal } from "../Modal/CustomModal";
import { MessageModal } from "../Modal/MessageModal";
import { UserModal } from "../Modal/UserModal";
import BreadCrumb from "../utils/BreadCrumb";
import { UserHeader } from "./UserHeader";
import { UserSearchBar } from "./UserSearchBar";

const { Panel } = Collapse;
const { Search } = Input;
/**
 * list of users with actions
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function UserList(props) {
  const skeletonLoading = props.skeletonLoading;
  const user = props.user.user;
  const users = props.users;
  const defaultUsersList = props.defaultUsersList;

  const [backgroundColor, setBackgroundColor] = useState(colors["WHITE"]);
  const [buttonColor, setButtonColor] = useState(colors["BLACK"]);
  const [collapsable, setCollapsable] = useState("header");
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setUser] = useState(user);
  const [textColor, setTextColor] = useState(colors["BLACK"]);
  const [companies, setCompanies] = useState([]);

  const theme = useSelector(selectTheme);

  const dispatch = useDispatch();

  const triggerAddUser = () => {
    setUser(undefined);
    setUserModalVisible(true);
  };

  useEffect(() => {
    if (companies.length == 0) {
      FetchCompanies().then((res) => {
        setCompanies(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    if (theme != undefined) {
      var actualTheme = theme[0].color;
      setTextColor(themeColors[actualTheme][3] != undefined ? themeColors[actualTheme][3] : colors["BLACK"]);
      setBackgroundColor(themeColors[actualTheme][2] != undefined ? themeColors[actualTheme][2] : colors["WHITE"]);
      setButtonColor(themeColors[actualTheme][0] != undefined ? themeColors[actualTheme][0] : colors["BLACK"]);
    }
  }, [theme]);

  const iconStyle = {
    boxShadow: "0 4px 8px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "10px",
  };

  const addUser = (newUser) => {
    var newUsers = JSON.parse(JSON.stringify(defaultUsersList));
    newUsers.push(newUser);
    dispatch(setUserList({ userList: newUsers }));
  };

  const editUser = (editedUser) => {
    var newUsers = JSON.parse(JSON.stringify(defaultUsersList)).map((newUser) => {
      if (newUser.id == editedUser.id) return editedUser;
      else return newUser;
    });
    dispatch(setUserList({ userList: newUsers }));
  };

  const openModal = (item) => {
    setUser(item);
    setIsModalVisible(true);
  };

  const deleteUser = () => {
    setLoading(true);
    DeleteUser(user.id, selectedUser.id).then(() => {
      var newUsers = JSON.parse(JSON.stringify(defaultUsersList));
      newUsers = newUsers.filter((newUser) => {
        return newUser.id.toString() != selectedUser.id.toString();
      });
      dispatch(setUserList({ userList: newUsers }));
      setLoading(false);
      setDeleteModalVisible(false);
      message.success("User Deleted Successfully!");
    });
  };

  const modifyRole = (item) => {
    item.role = item.role == "Admin" ? "User" : "Admin";
    message.loading("Changing Role...", 1000000);
    EditUser(item).then(() => {
      message.destroy();
      message.success("Role Changed!");
    });
  };

  useEffect(() => {}, []);

  const getMenu = (item) => {
    var menu = (
      <Menu
        style={{
          borderRadius: 12,
          border: `1px solid ${textColor}`,
          width: 220,
          boxShadow: "0px 4px 16px rgba(156, 181, 196, 0.2)",
        }}
        onMouseEnter={() => setCollapsable(false)}
        onMouseLeave={() => setCollapsable(true)}
      >
        <Menu.Item
          onClick={() => {
            openModal(item);
          }}
          key={item.id.toString() + "0"}
          style={{
            color: textColor,
            marginTop: 12,
            paddingLeft: 30,
          }}
          icon={generateIcon("mail", 30, 15, textColor)}
        >
          <a href="javascript:void(0);">Send Message</a>
        </Menu.Item>
        <Menu.Item
          key={user.id.toString() + "1"}
          onClick={() => {
            modifyRole(item);
          }}
          style={{
            color: textColor,
            paddingLeft: 30,
            marginTop: 12,
          }}
          icon={generateIcon("manageUsersOutlined", "30", "22", textColor)}
        >
          {item.role == "User" ? <a>Make Admin</a> : <a>Make User</a>}
        </Menu.Item>
        <Menu.Item
          key={user.id.toString() + "2"}
          onClick={() => {
            setUser(item);
            setUserModalVisible(true);
          }}
          style={{
            color: textColor,
            paddingLeft: 30,
            marginTop: 12,
          }}
          icon={generateIcon("userList", "30", "22", textColor)}
        >
          <a href="javascript:void(0);">Edit User Details</a>
        </Menu.Item>
        <Link to={`/users/${item.name}-${item.id}`}>
          <Menu.Item
            style={{
              color: textColor,
              paddingLeft: 30,
              marginTop: 12,
            }}
            icon={generateIcon("pen", "30", "22", textColor)}
          >
            Edit Apps
          </Menu.Item>
        </Link>
        <Menu.Divider />
        <Menu.Item
          key={user.id.toString() + "3"}
          onClick={() => {
            setUser(item);
            setDeleteModalVisible(true);
          }}
          style={{
            paddingLeft: 30,
            marginBottom: 12,
            marginTop: 6,
          }}
          danger
          icon={<MinusCircleFilled style={{ marginRight: 26 }} />}
        >
          Delete User
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown
        onMouseEnter={() => setCollapsable(false)}
        onMouseLeave={() => setCollapsable(true)}
        onClick={(event) => {
          event.stopPropagation();
        }}
        overlay={menu}
        trigger={["click"]}
        placement="bottomLeft"
      >
        <div style={{ display: "flex", cursor: "pointer" }}>{generateCircularIcon("options", 28, textColor, "4", "16")}</div>
        {/* <MoreOutlined style={{ fontSize: "20px", color: textColor }} /> */}
      </Dropdown>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <BreadCrumb
        color={buttonColor}
        style={{
          justifyContent: "left",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      />
      <div style={{ padding: "1%", flex: 1, marginTop: "24px" }}>
        <UserSearchBar
          triggerAddUser={triggerAddUser}
          disabled={skeletonLoading}
          companies={companies}
          textColor={textColor}
          buttonColor={buttonColor}
        ></UserSearchBar>
        <List
          style={{
            flex: 1,
            borderRadius: "20px",
            backgroundColor: backgroundColor,
            overflow: "hidden",
            padding: "0px 34px 10px 34px",
            position: "relative",
            zIndex: 1,
            boxShadow: "0px 2px 8px rgba(156, 181, 196, 0.05)",
          }}
        >
          {users.map((item) => (
            <List.Item actions={[skeletonLoading ? <></> : getMenu(item)]} key={item.id}>
              <UserHeader
                menu={skeletonLoading ? <></> : getMenu(item)}
                user={item}
                textColor={textColor}
                skeletonLoading={skeletonLoading}
              />
            </List.Item>
          ))}
        </List>
        <CustomModal
          centered
          color={textColor}
          loading={loading}
          visible={isDeleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onOk={deleteUser}
          innerText={
            <h3
              style={{
                marginTop: "5%",
                fontWeight: 700,
                fontSize: "20px",
                color: textColor,
              }}
            >
              Are you sure you want to delete this user: {selectedUser != undefined ? selectedUser.name : ""}?
            </h3>
          }
        ></CustomModal>
        {selectedUser != undefined ? (
          <MessageModal color={textColor} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} user={selectedUser} />
        ) : (
          <></>
        )}
        <UserModal
          addUser={addUser}
          editUser={editUser}
          color={textColor}
          companies={companies}
          user={selectedUser}
          isModalVisible={isUserModalVisible}
          setIsModalVisible={setUserModalVisible}
        ></UserModal>
      </div>
      {/* <Affix offsetBottom={120}>
        <Tooltip placement="top" title="Add Section">
          <Button
            type="primary"
            className="floatButton"
            onClick={() => {
              setUser(undefined);
              setUserModalVisible(true);
            }}
            shape="circle"
            style={{
              border: "none",
              backgroundColor: buttonColor,
              animation: "translate-y 3s ease-in-out 3s",
              float: "right",
              marginRight: "40px",
            }}
            icon={
              <PlusOutlined
                style={{ fontSize: "25px", color: backgroundColor }}
              />
            }
          />
        </Tooltip>
      </Affix> */}
    </div>
  );
}

export { UserList };
