import { Button, Col, Form, Input, message, Row } from "antd";
import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SendIssue } from "../api/notifications";
import { themeColors } from "../colors";
import BreadCrumb from "../components/utils/BreadCrumb";
import { generateIcon } from "../iconGenerator";
import { selectTheme } from "../store/filterSlice";
import { lightenDarkenColor } from "../utils";

/**
 * Page to report any issues or bugs or problems
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function HelpPage(props) {
  const user = props.user.user;
  const history = useHistory();
  const [buttonColor, setButtonColor] = useState(themeColors["Default"][0]);
  const [form] = Form.useForm();
  const [issue, setIssue] = useState("");
  const [textColor, setTextColor] = useState(themeColors["Default"][2]);

  const theme = useSelector(selectTheme);

  useEffect(() => {
    for (var i in theme) {
      if (theme[i].component == "Nav Bar") setButtonColor(themeColors[theme[i].color][0]);
      else if (theme[i].component == "Section Header") setTextColor(themeColors[theme[i].color][2]);
    }
  }, [theme]);

  const submitIssue = () => {
    if (issue == undefined || issue == "") {
      message.destroy();
      message.error("No issue to submit!");
    } else {
      message.loading("Reporting Issue", 1000000);
      SendIssue(user.email, issue).then((res) => {
        message.destroy();
        if (res.status != 200) {
          message.destroy();
          message.error(`Failed to issue message!`);
        } else {
          message.success("Issue successfully sent!");
          form.resetFields();
          setIssue("");
          history.push("/");
        }
      });
    }
  };

  return (
    <div>
      <div>
        <BreadCrumb
          color={buttonColor}
          style={{
            justifyContent: "left",
            marginTop: "2px",
          }}
        />
      </div>
      <div style={{ padding: "2%", marginTop: "32px" }}>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <div style={{ margin: "0 auto", width: "100%", justifyContent: "left" }}>
            <Form name="basic" labelAlign={"left"} form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 28 }} autoComplete="off">
              <Form.Item label="Your Name" name="name" initialValue={user.name}>
                <Input
                  disabled
                  style={{
                    backgroundColor: lightenDarkenColor(buttonColor, 120),
                    border: `1px solid ${lightenDarkenColor(buttonColor, 100)}`,
                    color: lightenDarkenColor(buttonColor, 20),
                    borderRadius: "8px",
                    minHeight: "36px",
                    fontSize: "14px",
                  }}
                />
              </Form.Item>
              <Form.Item label="Your Email" name="email" initialValue={user.email}>
                <Input
                  disabled
                  style={{
                    backgroundColor: lightenDarkenColor(buttonColor, 120),
                    border: `1px solid ${lightenDarkenColor(buttonColor, 100)}`,
                    color: lightenDarkenColor(buttonColor, 20),
                    borderRadius: "8px",
                    minHeight: "36px",
                    fontSize: "14px",
                  }}
                />
              </Form.Item>
              <Form.Item label="Question" name="issue">
                <TextArea
                  value={issue}
                  onChange={(val) => setIssue(val.target.value)}
                  placeholder="How can I help you?"
                  autoSize={{ minRows: 10 }}
                  style={{
                    border: `1px solid ${lightenDarkenColor(buttonColor, 120)}`,

                    backgroundColor: textColor,
                    color: buttonColor,
                    borderRadius: "8px",
                  }}
                />
              </Form.Item>
              <Form.Item style={{ padding: "1%" }}>
                <Row justify="end">
                  {" "}
                  <Button
                    onClick={submitIssue}
                    style={{
                      backgroundColor: buttonColor,
                      color: textColor,
                      height: "48px",
                      fontSize: "14px",
                      fontWeight: 600,
                      width: "117px",
                      lineHeight: "14px",
                      borderColor: buttonColor,
                      borderRadius: "24px",
                    }}
                    icon={
                      <Row
                        justify="center"
                        style={{
                          height: "16px",
                        }}
                      >
                        <Col style={{ marginRight: "8px" }}>{generateIcon("send", "13", "16", textColor, "0", "50")}</Col>
                        <Col
                          style={{
                            verticalAlign: "bottom",
                            fontSize: "14px",
                            fontWeight: 600,
                            paddingTop: "1px",
                          }}
                        >
                          SEND
                        </Col>
                      </Row>
                    }
                  ></Button>
                </Row>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HelpPage };
