export const colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  SILVER: "#C0C0C0",
  GRAY: "#808080",
  MAROON: "#800000",
  OLIVE: "#808000",
  LIME: "#00FF00",
  AQUA: "#00FFFF",
  TEAL: "#008080",
  NAVY: "#000080",
  FUCHSIA: "#FF00FF",
  PURPLE: "#800080",
  INDIANRED: "#CD5C5C",
  LIGHTCORAL: "#F08080",
  SALMON: "#FA8072",
  DARKSALMON: "#E9967A",
  LIGHTSALMON: "#FFA07A",
};

export const themeColors = {
  Default: ["#4b66af", "#e5ebf7", "#ffffff", "#484f68", false],
  Pinky: ["#f0637c", "#fdf1f7", "#ffffff", "#484f68", false],
  Yellow: ["#fcb613", "#fff9de", "#fffdf1", "#484f68", false],
  Purply: ["#3a3162", "#7669ae", "#dad6ec", "#3a3162", false],
  Exotic: ["#2c2c54", "#59c0a6", "#efeeed", "#357160", false],
  Tropical: ["#2c2c54", "#f37844", "#f7efe2", "#2c2c54", false],
  Lava: ["#40417b", "#ef5455", "#efeeed", "#2c2c54", false],
  Dawn: ["#202a36", ["#8177b5", "#43a6bb"], "#ffffff", "#484f68", true],
  Sunset: ["#202a36", ["#ed9d81", "#cc7dc6", "#5db6c9"], "#ffffff", "#444758", true],
  Waves: ["#212658", ["#3ec0a1", "#1d679e"], "#ffffff", "#212658", true],
  "Deep Sea": ["#3a3a68", ["#87abd7", "#b1f3fb"], "#f4f4f4", "#3a3a68", true],
};

/*
ThemeColorTargets:
Index 0:
-ButtonBackgroundColor
-TopbarColor
-TextOnBrightBackgroundColor

Index 1:
-PageBackgroundColorGradientLeft
-TopBarIconsAndTextColor

Index 2:
-PageBackgroundColorGradientRight

Index 3:
-AppSectionBackgroundColor
-UserSectionBackgroundColor
-TextAreaBackgroundColor
-AllCardsBackGroundColors

Index 4:
-DropdownTextColor
-SectionTitleTextColor
-AppTitlesTextColor

Index 5:
ApplyColorToPageBackground?

*/
