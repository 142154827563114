import { Button, Col, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generateIcon } from "../../iconGenerator";
import { setCompanyFilterValue, setRoleFilterValue, setSearchValue, setSortByValue } from "../../store/userSlice";
import { lightenDarkenColor } from "../../utils";
import { CollapseableSelect } from "../utils/CollapseableSelect";

const { Search } = Input;
const { Option, OptGroup } = Select;

const sortValues = {
  "Name: A to Z": ["name", "true"],
  "Name: Z to A": ["name", "false"],
  "Company: A to Z": ["name", "true"],
  "Company: Z to A": ["name", "false"],
};

/**
 * Search bar on user list page
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function UserSearchBar(props) {
  const companies = props.companies;
  const disabled = props.disabled;
  const textColor = props.textColor;
  const buttonColor = props.buttonColor;
  const triggerAddUser = props.triggerAddUser;

  const [searchValue, setSearch] = useState("");
  const [companiesDict, setCompaniesDict] = useState({});
  const [sortValue, setSortValue] = useState(undefined);
  const [companyFilter, setCompanyFilter] = useState(undefined);
  const [roleFilter, setRoleFilter] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    var dict = {};
    for (var index in companies) {
      var company = companies[index];
      dict[company.name] = company.id;
    }
    setCompaniesDict(dict);
  }, [companies]);

  useEffect(() => {
    if (sortValue != undefined && sortValue != "") {
      var sortBy = sortValue;
      dispatch(setSortByValue({ sortValue: sortBy }));
    } else {
      dispatch(setSortByValue({ sortValue: [] }));
    }
  }, [sortValue]);

  useEffect(() => {
    if (companyFilter != undefined && companyFilter != "") {
      dispatch(setCompanyFilterValue({ companyFilter: companyFilter }));
    } else {
      dispatch(setCompanyFilterValue({ companyFilter: "" }));
    }
  }, [companyFilter]);

  useEffect(() => {
    if (searchValue != undefined && searchValue != "") {
      dispatch(setSearchValue({ searchValue: searchValue }));
    } else {
      dispatch(setSearchValue({ searchValue: "" }));
    }
  }, [searchValue]);

  useEffect(() => {
    if (roleFilter != undefined && roleFilter != "") {
      dispatch(setRoleFilterValue({ roleFilter: roleFilter }));
    } else {
      dispatch(setRoleFilterValue({ roleFilter: "" }));
    }
  }, [roleFilter]);

  return (
    <div style={{ marginBottom: "7px", position: "relative", zIndex: 2 }}>
      <Row style={{ textAlign: "left", marginBottom: "7px" }}>
        <Col xs={{ span: 14 }} s={{ span: 14 }} md={{ span: 18 }} l={{ span: 18 }} xl={{ span: 21 }}>
          <Input
            style={{
              paddingLeft: "10px",
              border: `1px solid ${lightenDarkenColor(textColor, 120)}`,
              backgroundColor: "white",
              opacity: 0.8,
            }}
            className="my-search"
            disabled={disabled}
            placeholder="Search"
            onChange={(event) => setSearch(event.target.value)}
          ></Input>
        </Col>
        <Col style={{ textAlign: "right" }} xs={{ span: 10 }} s={{ span: 10 }} md={{ span: 6 }} l={{ span: 6 }} xl={{ span: 3 }}>
          <Button
            className="my-button"
            onClick={triggerAddUser}
            icon={
              <Row
                justify="center"
                style={{
                  height: "16px",
                }}
              >
                <Col style={{ marginRight: "8px" }}>{generateIcon("add", "13", "10", "white", "0", "59")}</Col>
                <Col
                  style={{
                    verticalAlign: "bottom",
                    fontSize: "14px",
                    fontWeight: 600,
                    paddingTop: "1px",
                  }}
                >
                  ADD USER
                </Col>
              </Row>
            }
            style={{
              width: "150px",
              color: "white",
              backgroundColor: buttonColor,
              borderColor: buttonColor,
            }}
          ></Button>
        </Col>
      </Row>
      <Row
        // justify="start"
        style={{
          // opacity: "0.9",
          padding: "0%",
          height: "48px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Col xs={{ span: 8 }} s={{ span: 8 }} md={{ span: 6 }} l={{ span: 7 }} xl={{ span: 7 }}>
          <Row justify="start">
            <CollapseableSelect
              style={{ height: "48px" }}
              color={textColor}
              placeholder={"Sort By"}
              disabled={disabled}
              selectValueParent={setSortValue}
              data={{
                "By Name": {
                  "Name: A to Z": ["name", true],
                  "Name: Z to A": ["name", false],
                },
                "By Company": {
                  "Company: A to Z": ["companyName", true],
                  "Company: Z to A": ["companyName", false],
                },
              }}
            ></CollapseableSelect>
          </Row>
        </Col>
        <Col xs={{ span: 8 }} s={{ span: 8 }} md={{ span: 6 }} l={{ span: 7 }} xl={{ span: 7 }}>
          <Row justify="space-around">
            <CollapseableSelect
              style={{ height: "48px" }}
              color={textColor}
              placeholder={"Filter By Company"}
              disabled={disabled}
              selectValueParent={setCompanyFilter}
              data={{
                "": companiesDict,
              }}
            ></CollapseableSelect>
          </Row>
        </Col>
        <Col xs={{ span: 8 }} s={{ span: 8 }} md={{ span: 6 }} l={{ span: 7 }} xl={{ span: 7 }}>
          <Row justify="end">
            <CollapseableSelect
              style={{ height: "48px" }}
              color={textColor}
              placeholder={"Filter By Role"}
              disabled={disabled}
              selectValueParent={setRoleFilter}
              data={{
                "": { User: "User", Admin: "Admin" },
              }}
            ></CollapseableSelect>
          </Row>
        </Col>

        <Col l={{ span: 12 }} m={{ span: 6 }} xl={{ span: 12 }} s={{ span: 6 }} xs={{ span: 0 }}>
          {/* empty column for widh distribution */}
        </Col>
      </Row>
    </div>
  );
}

export { UserSearchBar };
