import { Col, Input, Row } from "antd";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeColors } from "../../colors";
import { selectEditMode, selectTextColor, setEditMode, setEmptySearchText, setTextColor } from "../../store/editAppsSlice";
import { isDefault, selectTheme, setValue } from "../../store/filterSlice";
import { lightenDarkenColor } from "../../utils";

/**
 * Search bar and edit toggle
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function SearchBar() {
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const textColor = useSelector(selectTextColor);
  const editMode = useSelector(selectEditMode);
  const loading = useSelector(isDefault);

  const onSearchInputChange = (value) => {
    dispatch(setValue(value.target.value));
    dispatch(setEmptySearchText(value.target.value !== ""));
  };

  useEffect(() => {
    inputRef?.current?.focus({ cursor: "start" });
  }, []);

  useEffect(() => {
    dispatch(setEmptySearchText(loading));
    if (loading) dispatch(setEditMode(false));
  }, [loading]);

  useEffect(() => {
    dispatch(setTextColor(themeColors[theme[0].color][1][0]));
  }, [theme]);

  return (
    <Row
      style={{
        margin: "40px 0px 32px 0px",
        padding: "0px 20px 0px 10px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Col span={24}>
        <div style={{ width: "100%", textAlign: "left" }}>
          <Input
            disabled={editMode}
            style={{
              border: `1px solid ${lightenDarkenColor(textColor, 120)}`,
            }}
            allowClear
            className="my-search"
            placeholder="Search"
            onChange={onSearchInputChange}
            ref={inputRef}
          />
        </div>
      </Col>
    </Row>
  );
}

export { SearchBar };
