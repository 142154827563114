/**
 * Icon that shows the theme to be selected
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function ThemeIcon(props) {
  const backgroundColor = props.backgroundColor;
  const isGradient = props.isGradient;
  const topBarColor = props.topBarColor;

  const iconStyle = {
    width: "75px",
    height: "75px",
    borderRadius: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "14px",
  };

  const backgroundStyle = {
    width: "60px",
    height: "48px",
    position: "relative",
    backgroundColor: !isGradient ? backgroundColor : undefined,
    backgroundImage: isGradient
      ? "linear-gradient(to right, " + backgroundColor.join(", ") + " )"
      : undefined,
  };

  return (
    <div style={iconStyle}>
      <div
        style={{
          borderRadius: "12px",
          backgroundColor: topBarColor,
          height: "66%",
          width: "66%",
          marginLeft: "auto",
        }}
      ></div>
      <div
        style={{
          borderRadius: "12px",
          height: "66%",
          width: "66%",
          bottom: "34%",
          marginRight: "auto",
          position: "relative",
          backgroundColor: !isGradient ? backgroundColor : undefined,
          backgroundImage: isGradient
            ? "linear-gradient(to right, " + backgroundColor.join(", ") + " )"
            : undefined,
        }}
      ></div>
    </div>
  );
}

export { ThemeIcon };
