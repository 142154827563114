import { createSlice } from "@reduxjs/toolkit";
import { colors } from "../colors";

const initialState = {
  editMode: false,
  isSearchTextEmpty: false,
  textColor: colors.WHITE,
};

export const AppsSlice = createSlice({
  name: "Apps",
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setTextColor: (state, action) => {
      state.textColor = action.payload;
    },
    setEmptySearchText: (state, action) => {
      state.isSearchTextEmpty = action.payload;
    },
  },
});

export const { setEditMode, setTextColor, setEmptySearchText } = AppsSlice.actions;
export const selectTextColor = (state) => state.apps.textColor;
export const selectEditMode = (state) => state.apps.editMode;
export const selectIsSearchTextEmpty = (state) => state.apps.isSearchTextEmpty;
export default AppsSlice.reducer;
