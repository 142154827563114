import { Col, Row } from "antd";
import { themeColors } from "../colors";
import { ProfileInfo } from "../components/Profile/ProfileInfo";
import { EditThemes } from "../components/Theme/EditThemes";
import BreadCrumb from "../components/utils/BreadCrumb";

/**
 * Page to edit profile preferences
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function ProfilePage(props) {
  const googleImage = props.googleImage;
  const setDefaultTheme = props.setDefaultTheme;
  const setUser = props.setUser;
  const user = props.user;

  const style = {
    width: "100%",
    padding: "1% 1% 0% 1%",
  };

  const changeUser = (user1) => {
    var newUser = JSON.parse(JSON.stringify(user));
    newUser.user = user1;
    setUser(newUser);
  };

  return (
    <div>
      <BreadCrumb
        color={themeColors[user.preferences[0].color][3]}
        style={{
          justifyContent: "left",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      />
      <Row style={{ minHeight: "50vh" }}>
        <Col lg={{ span: 8 }} style={style}>
          <ProfileInfo
            color={themeColors[user.preferences[0].color][3]}
            setUser={changeUser}
            googleImage={googleImage}
            user={user.user}
          ></ProfileInfo>
        </Col>
        <Col lg={{ span: 16 }} style={style}>
          <EditThemes
            user={user.user}
            defaultTheme={user.preferences}
            setDefaultTheme={setDefaultTheme}
          ></EditThemes>
        </Col>
      </Row>
    </div>
  );
}

export { ProfilePage };
