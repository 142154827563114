import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { FetchAllUsers } from "../api/user";
import { themeColors } from "../colors";
import { AddSectionPanel } from "../components/Sections/AddSectionPanel";
import { SectionList } from "../components/Sections/SectionList";
import { UserList } from "../components/Users/UserList";
import BreadCrumb from "../components/utils/BreadCrumb";
import { selectEditMode } from "../store/editAppsSlice";
import { selectTheme } from "../store/filterSlice";
import { loadingUsers, selectUserFilter, setUserList } from "../store/userSlice";
import { lightenDarkenColor } from "../utils";

/**
 * Page containing user list and all its functionalities
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function UsersPage(props) {
  const user = props.user;

  const [iconColor, setColor] = useState(
    typeof themeColors["Default"][1] == "string" ? themeColors["Default"][1] : themeColors["Default"][0][0]
  );
  const [textColor, setTextColor] = useState(themeColors["Default"][2]);
  const [newSectionModalVisible, setNewSectionModalVisible] = useState(false);
  const [borderColor, setBorderColor] = useState(themeColors["Default"][3]);

  const defaultUsersList = useSelector((state) => state.user.value.userList);
  const users = useSelector(selectUserFilter);
  const theme = useSelector(selectTheme);
  const editMode = useSelector(selectEditMode);

  const dispatch = useDispatch();

  useEffect(() => {
    for (var i in theme) {
      if (theme[i].component == "Nav Bar") {
        setColor(themeColors[theme[i].color][0]);
        setBorderColor(themeColors[theme[i].color][3]);
      } else if (theme[i].component == "Section Header") setTextColor(themeColors[theme[i].color][2]);
    }
  }, [theme]);

  useEffect(() => {
    FetchAllUsers(user.user.email).then((res) => {
      dispatch(setUserList({ userList: res }));
    });
  }, [user]);

  if (user.user.role == "Admin") {
    return (
      <div style={{ display: "flex", flexFlow: "column", flex: 1 }}>
        <Route exact path="/users">
          <UserList
            style={{ margin: "0px 10px 0px 10px" }}
            defaultUsersList={defaultUsersList}
            users={users}
            user={user}
            skeletonLoading={JSON.stringify(users) == JSON.stringify(loadingUsers)}
          />
        </Route>

        {users.map((selectedUser) => {
          return (
            <Route exact path={`/users/${selectedUser.name}-${selectedUser.id}`} key={selectedUser.id}>
              <div
                style={{
                  height: "100%",
                  margin: 0,
                  display: "flex",
                  flexFlow: "column",
                  flex: 1,
                }}
              >
                <BreadCrumb
                  color={themeColors["Default"][0]}
                  style={{
                    justifyContent: "left",
                    marginTop: "8px",
                    marginBottom: "0px",
                  }}
                />
                <Row
                  style={{
                    margin: "20px",
                  }}
                >
                  <Col span={19}>
                    <Row justify="start" style={{ width: "100%" }}>
                      <Col span={24}>
                        <Card
                          style={{
                            borderRadius: "20px",
                            marginTop: "15px",
                            backgroundColor: lightenDarkenColor(borderColor, 120),
                            // boxShadow: "0 1px 46px -4px rgb(0 0 0 / 28%)",
                            // opacity: 0.7,
                          }}
                          headStyle={{
                            fontSize: "25px",
                            textAlign: "initial",
                            maxHeight: "48px",
                            borderBottom: "0px",
                          }}
                        >
                          <Card.Meta
                            description={`${selectedUser.companyName} - ${selectedUser.role}`}
                            style={{
                              paddingLeft: "34px",
                              paddingTop: "28px",
                              paddingBottom: "14px",
                              // fontStyle: "italic",
                              textAlign: "left",
                            }}
                            title={
                              <Typography.Text
                                style={{
                                  fontSize: "24px",
                                  fontWeight: 700,
                                  color: borderColor,
                                }}
                              >
                                {selectedUser.name}
                              </Typography.Text>
                            }
                            avatar={
                              selectedUser.image == undefined || selectedUser.image == "null" ? (
                                <Avatar size={52} style={{ marginRight: "20px" }} icon={<UserOutlined />} />
                              ) : (
                                <Avatar style={{ marginRight: "20px" }} shape={"circle"} size={52} src={selectedUser.image} />
                              )
                            }
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col flex="40px"></Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    margin: "10px",
                    alignContent: "center",
                    height: "100%",
                    flex: 1,
                  }}
                >
                  <AddSectionPanel setNewSectionModalVisible={setNewSectionModalVisible} borderColor={borderColor} editMode={editMode} />{" "}
                  <SectionList
                    defaultLogos={props.defaultLogos}
                    user={selectedUser}
                    modalColor={borderColor}
                    newSectionModalVisible={newSectionModalVisible}
                    setNewSectionModalVisible={setNewSectionModalVisible}
                    textColor={textColor}
                    backgroundColor={iconColor}
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      width: "100%",
                      height: "100%",
                      flex: 1,
                    }}
                  />
                </div>
              </div>
            </Route>
          );
        })}
      </div>
    );
  } else {
    return (
      <Result
        status="403"
        title="Sorry!"
        subTitle="You are not authorized to access this page."
        extra={
          <Link to="/">
            <Button
              size="large"
              style={{
                backgroundColor: iconColor,
                color: textColor,
                borderRadius: "8px",
              }}
            >
              Back Home
            </Button>
          </Link>
        }
      />
    );
  }
}

export { UsersPage };
