import { ContainerOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, message, Row } from "antd";
import "antd/dist/antd.css";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddApp, EditApp } from "../../api/bookmark";
import { addApp, editApp } from "../../store/filterSlice";
import { handleImageFetchFail, isImageInvalid, isValidHttpUrl, lightenDarkenColor } from "../../utils";
import { ModalInput } from "../utils/ModalInput";
import { EditableTagGroup } from "../utils/Tags";

const imageTypes = ["jpg", "jpeg", "png", "svg", "gif"];
const imageRetrieverBaseUrl =
  "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&drop_404_icon=true&size=64&url=";
// "https://s2.googleusercontent.com/s2/favicons?domain_url=";
const { Panel } = Collapse;

/**
 * The modal used to create and edit applications.
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function AppModal(props) {
  const id = props.id;
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const color = props.color;
  const isNew = props.isNew != undefined ? props.isNew : true;
  const defaultLogos = props.defaultLogos;

  const [cancelActive, setCancelActive] = useState(true);
  const [customUrl, setCustomUrl] = useState(props.logo != undefined ? props.logo : "");
  const [key, setKey] = useState("0");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(props.logo != undefined ? props.logo : "error");
  const [newName, setName] = useState(props.name != undefined ? props.name : "");
  const [tags, setTags] = useState(props.tags != undefined ? props.tags : []);
  const [url, setUrl] = useState(props.url != undefined ? props.url : "");
  const [logoIsCustom, setLogoCustom] = useState(false);

  const dispatch = useDispatch();

  const handleAppSave = () => {
    if (newName == "" || logo == "error" || url == "") {
      message.destroy();
      message.error("Some fields are still empty!");
    } else if (!isValidHttpUrl(url)) {
      message.destroy();
      message.error("URLs should be valid! Example: https://www.google.com/");
    } else {
      setLoading(true);
      isNew ? createApp() : setApp();
    }
  };

  const createApp = () => {
    AddApp(id, newName, url, logo, tags, isImageInvalid(logo, newName), logoIsCustom).then((appId) => {
      let newApp = {};
      newApp["id"] = appId.id;
      newApp["name"] = newName;
      newApp["url"] = url;
      newApp["logo"] = appId.logo;
      newApp["tags"] = tags;
      dispatch(addApp({ app: newApp, sectionId: id }));
      clearFields();
      setLoading(false);
      setCancelActive(true);
      setIsModalVisible(false);
      message.success("New app added!");
    });
  };

  const setApp = () => {
    setCancelActive(false);
    EditApp(id, newName, url, logo, tags, isImageInvalid(logo, newName), logoIsCustom).then((res) => {
      let newApp = {};
      newApp["id"] = id;
      newApp["name"] = newName;
      newApp["url"] = url;
      newApp["logo"] = logo;
      newApp["tags"] = tags;
      dispatch(editApp({ app: newApp }));
      clearFields();
      setLoading(false);
      setCancelActive(true);
      setIsModalVisible(false);
      message.success("App edited!");
    });
  };

  const clearFields = () => {
    setName(props.name != undefined ? props.name : "");
    setUrl(props.url != undefined ? props.url : "");
    setTags(props.tags != undefined ? props.tags : []);
    setLogo(props.logo != undefined ? props.logo : "error");
    setCustomUrl("");
    setKey("0");
    setLoading(false);
    setCancelActive(true);
  };

  useEffect(() => {
    if (customUrl != "") {
      image = customUrl;
      setLogo(image);
      setLogoCustom(true);
    } else if (url != "" && url != props.url) {
      var urlParts = url.split("//");
      var mainUrl = urlParts[0];
      if (urlParts.length > 1) mainUrl = urlParts[1];
      mainUrl = mainUrl.split("/")[0];

      var image = imageRetrieverBaseUrl.concat("http://");
      var image = image.concat(mainUrl);

      for (let index in defaultLogos.appLogos) {
        if (url.includes(index)) {
          image = defaultLogos.appLogos[index];
        }
      }
      var urlSplit = url.split(".");
      var extension = urlSplit[urlSplit.length - 1];
      if (defaultLogos.extLogos[extension] != undefined) {
        image = defaultLogos.extLogos[extension];
      }
      if (imageTypes.includes(extension.toLowerCase())) {
        image = url;
      }

      setLogo(image);
      if (props.name == undefined || newName == "") {
        var name = url.replace(/.+\/\/|www.|\..+/g, "");
        name = name.charAt(0).toUpperCase() + name.slice(1);
        setName(name);
      }
      setLogoCustom(false);
    } else {
      // setKey("0");
      setLogoCustom(false);
      setName(props.name != undefined ? props.name : "");
      if (props.logo != undefined) setLogo(props.logo);
      else setLogo("error");
      if (customUrl != "") setCustomUrl("");
    }
  }, [url, customUrl]);

  const saveImageUrl = () => {
    // setLogo
    setKey("0");
  };

  const pastCustomUrl = () => {
    navigator.clipboard.readText().then((res) => setCustomUrl(res));
  };

  const onPressEnter = () => {
    if (!loading) handleAppSave();
  };

  const APP_NAME_MAX_LENGTH = 50;

  const tagsTitleStyle = {
    marginTop: "5%",
    fontWeight: 500,
    fontSize: "14px",
    color: color,
  };

  const imageSaveButtonStyle = {
    marginTop: "6px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    border: `1px solid ${color}`,
    color: color,
    fontWeight: 700,
    fontSize: "12px",
    borderRadius: "16px",
    height: "32px",
    lineHeight: "21px",
  };

  const selectImageLinkButtonStyle = {
    fontSize: "12px",
    backgroundColor: "#ffffff",
    border: `1px solid ${color}`,
    color: color,
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "16px",
    height: "32px",
    lineHeight: "21px",
    width: "162px",
  };

  const logoHeaderStyle = {
    marginTop: "5%",
    fontWeight: 500,
    fontSize: "14px",
    color: color,
  };

  const nameHeaderStyle = {
    marginTop: "5%",
    fontWeight: 500,
    fontSize: "14px",
    color: color,
  };

  const urlHeaderStyle = {
    marginTop: "5%",
    fontWeight: 500,
    fontSize: "14px",
    color: color,
  };

  const modalContainerHeaderStyle = { padding: "2% 5% 5% 5%" };

  const modalHeaderStyle = {
    marginTop: "5%",
    color: color,
    fontSize: "20px",
    fontWeight: 700,
  };

  const modalStyle = {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: lightenDarkenColor(color, 25),
    borderRadius: "8px",
    border: `1px solid ${lightenDarkenColor(color, 25)}`,
  };

  const cancelButtonStyle = {
    border: `1.5px solid ${color}`,
    color: color,
  };

  const okButtonStyle = { backgroundColor: color, color: "white" };

  return (
    <Modal
      style={modalStyle}
      closable={cancelActive}
      okButtonProps={{
        loading: loading,
        style: okButtonStyle,
        children: "SAVE",
      }}
      cancelButtonProps={{
        disabled: !cancelActive,
        style: cancelButtonStyle,
      }}
      okText="SAVE"
      cancelText="CANCEL"
      visible={isModalVisible}
      onOk={handleAppSave}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
    >
      <div style={modalContainerHeaderStyle}>
        <h3 style={modalHeaderStyle}>{isNew ? "Add" : "Edit"} App</h3>
        <h3 style={urlHeaderStyle}>Url:</h3>
        <ModalInput placeholder="App Url" color={color} onChange={setUrl} onPressEnter={onPressEnter} isFilled={url == ""} value={url} />
        <h3 style={nameHeaderStyle}>Name:</h3>
        <ModalInput
          placeholder="App Name"
          color={color}
          onChange={setName}
          isFilled={newName == ""}
          onPressEnter={onPressEnter}
          value={newName}
          maxLength={APP_NAME_MAX_LENGTH}
        />
        <h3 style={logoHeaderStyle}>Logo:</h3>
        <img
          width={100}
          height={100}
          src={logo}
          style={{ borderRadius: "50%" }}
          onError={() => handleImageFetchFail(url, newName, logo, setLogo, logoIsCustom)}
        />
        <h3 style={{ marginTop: "2%" }}></h3>
        <Collapse
          className="custom-actions"
          ghost
          accordion={false}
          bordered={false}
          activeKey={url != "" ? "urlAvailable" : "urlUnavailable"}
        >
          <Panel
            placeholder="Custom Url"
            className="url-new-input"
            showArrow={false}
            header={<></>}
            key="urlAvailable"
            style={{ padding: "0px" }}
          >
            <Button
              size={"small"}
              style={selectImageLinkButtonStyle}
              onClick={() => {
                setKey(key == "0" ? "1" : "0");
                if (key == "1") setCustomUrl("");
              }}
            >
              {key == "0" ? "Change Image Url" : "Cancel"}
            </Button>
            <Collapse className="custom-actions" ghost accordion={false} bordered={false} activeKey={key}>
              <Panel placeholder="Custom Url" className="url-new-input" showArrow={false} header={<></>} key="1" style={{ padding: "0px" }}>
                <Row>
                  <Col span={20}>
                    <ModalInput
                      color={color}
                      isFilled={customUrl == ""}
                      onChange={setCustomUrl}
                      placeholder={"Custom Url"}
                      onPressEnter={onPressEnter}
                      value={customUrl}
                      addonAfter={<ContainerOutlined onClick={pastCustomUrl} />}
                    />
                  </Col>
                  <Col span={4}>
                    <Row justify="end">
                      <Button size={"small"} style={imageSaveButtonStyle} onClick={() => saveImageUrl()}>
                        Save
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
        <h3 style={tagsTitleStyle}>Tags:</h3>
        <EditableTagGroup color={color} tags={tags} setTags={setTags}></EditableTagGroup>
      </div>
    </Modal>
  );
}

export { AppModal };
