import { message, Select } from "antd";
import { ConfigProvider, CountryPhoneInput, defaultAreas } from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import Modal from "antd/lib/modal/Modal";
import { parseDomain, ParseResultType } from "parse-domain";
import { useEffect, useState } from "react";
import en from "world_countries_lists/data/countries/en/world.json";
import { EditUser, SignUp } from "../../api/user";
import { lightenDarkenColor, sortByName } from "../../utils";
import { ModalInput } from "../utils/ModalInput";

const areas = defaultAreas;

/**
 * Modal to create or edit user (not personal user)
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function UserModal(props) {
  const addUser = props.addUser;
  const companies = props.companies;
  const editUser = props.editUser;
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const user = props.user;
  const color = props.color;
  const PHONE_NUMBER_REGEX = /\d{8}/;

  const [company, setCompany] = useState(user != undefined && user.companyId !== undefined ? user.companyId : "");
  const [email, setEmail] = useState(user != undefined && user.name !== undefined ? user.name : "");
  const [loading, setLoading] = useState(false);
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState({ short: "LB" });
  const [newName, setName] = useState(user != undefined && user.name !== undefined ? user.name : "");

  useEffect(() => {
    if (isModalVisible) {
      setName(user != undefined && user.name != undefined ? user.name : "");
      setEmail(user != undefined && user.email != undefined ? user.email : "");
      setCompany(user != undefined && user.companyId != undefined ? user.companyId : companies.length > 0 ? companies[0].id : "");
      setMobilePhoneNumber(
        user != undefined && user.phone != undefined && user.phone.trim() != ""
          ? {
              short: areas.filter((area) => {
                return area.phoneCode == user.phone.split(" ")[0];
              })[0].short,
              phone: user.phone.split(" ")[1],
              code: user.phone.split(" ")[0],
            }
          : { short: "LB" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    var splitEmail = email.split("@");
    var parseResult = parseDomain(splitEmail[0]);
    if (user == undefined && parseResult.type === ParseResultType.Listed) {
      const { _, domain, __ } = parseResult;
      var suggestedCompany = JSON.parse(JSON.stringify(companies)).filter((company) => {
        return company.name.toLowerCase() == domain;
      });
      if (suggestedCompany.length > 0) setCompany(suggestedCompany[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const clearFields = () => {
    setName(user != undefined && user.name != undefined ? user.name : "");
    setEmail(user != undefined && user.email != undefined ? user.email : "");
    setMobilePhoneNumber(
      user != undefined && user.phone != undefined && user.phone.trim() != ""
        ? {
            short: areas.filter((area) => {
              return area.phoneCode == user.phone.split(" ")[0];
            })[0].short,
            phone: user.phone.split(" ")[1],
          }
        : { short: "LB", code: 961 }
    );
    setCompany(user != undefined && user.companyId != undefined ? user.companyId : companies.length > 0 ? companies[0].id : "");
  };

  const handleChange = (value) => {
    setCompany(value);
  };

  const handleUserSave = (newUser) => {
    if (!email || !mobilePhoneNumber["phone"] || !newName || email == "" || mobilePhoneNumber["phone"] == "" || newName == "") {
      message.destroy();
      message.error("Fill All Required Fields!");
    } else if (!mobilePhoneNumber["phone"].match(PHONE_NUMBER_REGEX)) {
      message.destroy();
      message.error("Phone number invalid! Correct Format Example : 01847251");
    } else {
      setLoading(true);
      const newUser = user == undefined;
      newUser ? addNewUser() : modifyUser();
    }
  };

  const addNewUser = () => {
    SignUp(
      email,
      newName,
      [
        mobilePhoneNumber["code"],
        mobilePhoneNumber["phone"].charAt(0) != "0" ? mobilePhoneNumber["phone"] : mobilePhoneNumber["phone"].slice(1),
      ].join(" "),
      company,
      null
    ).then((res) => {
      res.companyId = res.company_id;
      var newCompany = JSON.parse(JSON.stringify(companies)).filter((company) => company.id == res.companyId)[0];
      res.companyName = newCompany.name;
      addUser(res);
      setLoading(false);
      setIsModalVisible(false);
      clearFields();
      message.success("New User Added!");
    });
  };

  const modifyUser = () => {
    user.email = email;
    var selectedCompany = companies.find((elementCompany) => elementCompany.id === company);
    user.companyId = selectedCompany.id;
    user.companyName = selectedCompany.name;
    user.companyLogo = selectedCompany.logo;
    user.phone = [
      mobilePhoneNumber["code"],
      mobilePhoneNumber["phone"].charAt(0) != "0" ? mobilePhoneNumber["phone"] : mobilePhoneNumber["phone"].slice(1),
    ].join(" ");
    user.name = newName;

    EditUser(user).then(() => {
      editUser(JSON.parse(JSON.stringify(user)));
      setLoading(false);
      setIsModalVisible(false);
      clearFields();
      setLoading(false);
      message.success("User edited Successfully!");
    });
  };

  return (
    <Modal
      style={{
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "14px",
        color: lightenDarkenColor(color, 25),
        borderRadius: "8px",
        border: `1px solid ${lightenDarkenColor(color, 25)}`,
      }}
      visible={isModalVisible}
      onOk={handleUserSave}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
      okButtonProps={{
        loading: loading,
        style: { backgroundColor: color, color: "white" },
        children: "SAVE",
      }}
      cancelButtonProps={{
        style: {
          border: `1.5px solid ${color}`,
          children: "CANCEL",
          color: color,
        },
      }}
    >
      <h3
        style={{
          marginTop: "5%",
          color: color,
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        {user != undefined ? "Edit User" : "Add User"}
      </h3>
      <h3 style={{ marginTop: "5%", color: color }}>Name:</h3>
      <ModalInput placeholder="Name" color={color} isFilled={newName == ""} onChange={setName} value={newName}></ModalInput>
      <h3 style={{ marginTop: "5%", color: color }}>Email:</h3>
      <ModalInput placeholder="Email" color={color} isFilled={email == ""} onChange={setEmail} value={email}></ModalInput>
      <h3 style={{ marginTop: "5%", color: color }}>Phone Number:</h3>
      <ConfigProvider locale={en} areaSorter={(a, b) => sortByName(a, b)}>
        <CountryPhoneInput
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={mobilePhoneNumber}
          style={{
            border: `1px solid ${lightenDarkenColor(color, 120)}`,
            color: color,
            fontWeight: 500,
            backgroundColor: !("phone" in mobilePhoneNumber) || mobilePhoneNumber["phone"] == "" ? "white" : lightenDarkenColor(color, 140),
          }}
          onChange={(v) => {
            setMobilePhoneNumber(v);
          }}
        />
      </ConfigProvider>
      <h3 style={{ marginTop: "5%", color: color }}>Company:</h3>
      <Select
        value={company}
        onChange={handleChange}
        style={{
          borderRadius: "8px",
          width: "100%",
          color: color,
          border: `1px solid ${lightenDarkenColor(color, 120)}`,
        }}
      >
        {companies.map((company) => {
          return (
            <Select.Option style={{ color: color }} value={company.id} key={company.id}>
              {company.name}
            </Select.Option>
          );
        })}
      </Select>
      {/* <h3 style={{marginTop: "5%"}}>Role:</h3>
        <Select  defaultValue={"User"} onChange={setRole} value={role} style={{borderRadius: '8px'}}>
          <Select.Option value={"User"} key={"User"}>User</Select.Option>
          <Select.Option value={"Admin"} key={"Admin"}>Admin</Select.Option>
        </Select> */}
    </Modal>
  );
}

export { UserModal };
