import { axiosURL } from "../config/axios";

const CreateSection = (email, component, isDefault) => {
  let form = new FormData();
  form.append("email", email);
  form.append("category", component);
  form.append("default", isDefault);
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/bookmark/category/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const AddApp = (sectionId, name, url, logoUrl, tags, imageInvalid, logoIsCustom) => {
  let form = {};
  form["name"] = name;
  form["section"] = sectionId;
  form["url"] = url;
  form["logo"] = logoUrl;
  form["tag"] = tags.join(",");
  form["imageInvalid"] = imageInvalid;
  form["logoIsCustom"] = logoIsCustom;
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/bookmark/application/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const EditApp = (sectionId, name, url, logoUrl, tags, imageInvalid, logoIsCustom) => {
  let form = {};
  form["name"] = name;
  form["id"] = sectionId;
  form["url"] = url;
  form["logo"] = logoUrl;
  form["tag"] = tags;
  form["imageInvalid"] = imageInvalid;
  form["logoIsCustom"] = logoIsCustom;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/bookmark/application/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const DeleteApp = (id) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .delete("/bookmark/application", { data: { id: id } })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const GetApps = (id) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/bookmark/application/", { params: { section: id } })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const GetSections = (email) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/bookmark/category/", { params: { email: email } })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const EditSectionTitle = (id, name) => {
  let body = {};
  body["id"] = id;
  body["name"] = name;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/bookmark/category/", body)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const DeleteSection = (id) => {
  let body = {};
  body["id"] = id;
  return new Promise((resolve, reject) => {
    axiosURL
      .delete("/bookmark/category", { data: { id: id } })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const ReIndex = (newOrder) => {
  let body = {};
  body["apps"] = newOrder;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/bookmark/applicationIndex/", body)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const ReIndexSections = (newOrder) => {
  let body = {};
  body["sections"] = newOrder;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/bookmark/categoryIndex/", body)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const GetLogos = () => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/bookmark/logos/")
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const LogInvalidImageLink = (appLink, logoLink) => {
  let body = {};
  body["appLink"] = appLink;
  body["logoLink"] = logoLink;
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/bookmark/logbrokenlogos/", body)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

export {
  CreateSection,
  AddApp,
  EditApp,
  DeleteApp,
  GetApps,
  GetSections,
  EditSectionTitle,
  DeleteSection,
  ReIndex,
  ReIndexSections,
  GetLogos,
  LogInvalidImageLink,
};
