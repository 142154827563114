import { configureStore } from "@reduxjs/toolkit";
import AppsSliceReducer from "./editAppsSlice";
import filterReducer from "./filterSlice";
import userFilterReducer from "./userSlice";

export default configureStore({
  reducer: {
    filter: filterReducer,
    user: userFilterReducer,
    apps: AppsSliceReducer,
  },
});
