import { UserOutlined } from "@ant-design/icons";
import { Col, Comment, Image, Row, Skeleton, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { generateIcon } from "../../iconGenerator";

/**
 * Header display in user list
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function UserHeader(props) {
  const skeletonLoading = props.skeletonLoading;
  const textColor = props.textColor;
  const user = props.user;

  return (
    <Skeleton paragraph={{ rows: 1 }} avatar active size={"small"} loading={skeletonLoading}>
      <Comment
        style={{
          display: "inline-block",
          marginTop: "auto",
          marginBottom: "auto",
          width: "90%",
        }}
        content={
          <Row style={{ width: "100%", marginTop: "24px" }}>
            <Col
              // span={6}
              xs={{ span: 24 }}
              s={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
              xl={{ span: 5 }}
              // flex={"auto"}
            >
              <div
                style={{
                  textAlign: "left",
                  lineHeight: "21px",
                  display: "block",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    height: "16px",
                    textOverflow: "ellipsis",
                    verticalAlign: "middle",
                  }}
                >
                  {generateIcon("phone", 25, 16, textColor)}
                </div>
                {user.phone == undefined || user.phone.trim() == "" ? (
                  <Text
                    italic
                    style={{
                      verticalAlign: "middle",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    <Text style={{ opacity: 0.5 }}>(no phone number)</Text>
                  </Text>
                ) : (
                  <Text
                    style={{
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      margin: "auto",
                      color: textColor,
                    }}
                  >
                    {" "}
                    +{user.phone}
                  </Text>
                )}
              </div>
            </Col>
            <Col
              // span={10}
              xs={{ span: 24 }}
              s={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
            >
              <div
                style={{
                  textAlign: "left",
                  lineHeight: "21px",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    height: "16px",
                    verticalAlign: "middle",
                  }}
                >
                  {generateIcon("mail", 30, 25, textColor)}
                </div>
                <Text
                  ellipsis={true}
                  style={{
                    textOverflow: "ellipsis-word",
                    fontSize: "14px",
                    fontWeight: 400,
                    overflow: "hidden",
                    display: "inline-block",
                    color: textColor,
                  }}
                >
                  {user.email}
                </Text>
              </div>
            </Col>
            <Col xs={{ span: 24 }} s={{ span: 24 }} md={{ span: 7 }} lg={{ span: 6 }} xl={{ span: 9 }}>
              <div
                style={{
                  textAlign: "left",
                  lineHeight: "21px",
                  display: "block",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    height: "16px",
                    verticalAlign: "middle",
                  }}
                >
                  {generateIcon("company", 28, 16, textColor)}
                </div>
                <Text
                  ellipsis={true}
                  style={{
                    textOverflow: "ellipsis-word",
                    fontSize: "14px",
                    fontWeight: 400,
                    overflow: "hidden",
                    display: "inline-block",
                    color: textColor,
                  }}
                >
                  {user.companyName}
                </Text>
              </div>
            </Col>
          </Row>
        }
        avatar={
          user.image == undefined || user.image == "null" || user.image == null ? (
            <div
              style={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "gray",
              }}
            >
              <UserOutlined style={{ color: "white", fontSize: "52px" }} />
            </div>
          ) : (
            <Image
              src={user.image}
              style={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              preview={false}
            ></Image>
          )
        }
        author={
          <>
            <Typography.Text
              className="text"
              style={{
                fontSize: "18px",
                height: "100%",
                textAlign: "left",
                fontWeight: 700,
                color: textColor,
              }}
            >
              {user.name}
            </Typography.Text>
            {user.role == "Admin" ? (
              <Typography.Text
                className="text"
                style={{
                  fontSize: "14px",
                  height: "100%",
                  marginLeft: "4px",
                  marginBottom: "4px",
                  textAlign: "left",
                  fontWeight: 400,
                  color: textColor,
                }}
              >
                ({user.role})
              </Typography.Text>
            ) : (
              <></>
            )}
          </>
        }
      ></Comment>
    </Skeleton>
  );
}

export { UserHeader };
