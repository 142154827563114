import { useAuth0 } from "@auth0/auth0-react";
import { Affix, Layout, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GetLogos } from "./api/bookmark";
import { FetchUserInfo } from "./api/user";
import { themeColors } from "./colors";
import { TopBar } from "./components/NavBar/TopBar";
import { selectTheme, setSections } from "./store/filterSlice";
import { HelpPage } from "./views/HelpPage";
import { MessagePage } from "./views/MessagePage";
import { ProfilePage } from "./views/ProfilePage";
import { SectionPage } from "./views/SectionPage";
import { SignUpPage } from "./views/SignUpPage";
import { UsersPage } from "./views/UsersPage";

const { Header, Content } = Layout;

console.log("Version 1.0.1");

/**
 * Main Page
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function App() {
  const { isAuthenticated, loginWithRedirect, isLoading, logout, user } = useAuth0();
  const [color, setColor] = useState(undefined);
  const [fetchedUser, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [newSectionModalVisible, setNewSectionModalVisible] = useState(false);
  const [status, setStatus] = useState(200);
  const [googleImage, setGoogleImage] = useState("");
  const [defaultLogos, setDefaultLogos] = useState();

  const defaultTheme = useSelector(selectTheme);

  useEffect(() => {
    for (var i in defaultTheme) {
      if (defaultTheme[i].component == "Section Background") {
        setColor(
          themeColors[defaultTheme[i].color][4]
            ? {
                backgroundImage: "linear-gradient(to right, " + themeColors[defaultTheme[i].color][1].join(", ") + " )",
              }
            : {
                backgroundColor: themeColors[defaultTheme[i].color][1],
              }
        );
      }
    }
  }, [defaultTheme]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !isLoading && window.location.pathname != "/signup") {
      setGoogleImage(user.picture);
      FetchUserInfo(user.email, user.picture).then((res) => {
        if (res.status != 200) {
          setStatus(404);
          if (window.location.pathname != "/signup") window.location.assign("/signup");
          setLoading(false);
        } else {
          res = res.data;
          res.user.email = user.email;
          dispatch(setSections({ defaultTheme: res.preferences }));
          setUser(res);
          setLoading(false);
        }
      });
    } else if (window.location.pathname == "/signup") {
      setLoading(false);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Fetch & store default logos for later use
  useEffect(() => {
    GetLogos().then((fetchedLogos) => {
      setDefaultLogos(fetchedLogos);
    });
  }, []);

  if (isLoading || (isAuthenticated && loading)) {
    return (
      <div style={{ margin: "20px", alignContent: "center", flex: 1 }}>
        <Row justify="center">
          <Spin tip="Logging In..." size="large" style={{ paddingTop: "5vh" }} />
        </Row>
      </div>
    );
  } else if (isAuthenticated) {
    return (
      <Router>
        <div className="App">
          <Layout
            style={Object.assign(
              {},
              {
                minHeight: "100vh",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              },
              color
            )}
          >
            <Header style={{ height: "auto" }}></Header>
            <Content style={{ display: "flex", flexDirection: "column" }}>
              {fetchedUser == undefined ? (
                <></>
              ) : (
                <Affix offsetTop={0}>
                  <TopBar user={fetchedUser} logout={logout} />
                </Affix>
              )}
              <div
                style={{
                  width: "100%",
                  maxWidth: "1300px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Switch>
                  <Route exact path="/">
                    <SectionPage
                      style={{ display: "flex", flexDirection: "column" }}
                      backgroundColor={defaultTheme[2] != undefined ? themeColors[defaultTheme[2].color][0] : themeColors["Default"][0]}
                      borderColor={defaultTheme[2] != undefined ? themeColors[defaultTheme[2].color][3] : themeColors["Default"][0]}
                      fetchedUser={fetchedUser}
                      newSectionModalVisible={newSectionModalVisible}
                      setNewSectionModalVisible={setNewSectionModalVisible}
                      textColor={defaultTheme[2] != undefined ? themeColors[defaultTheme[2].color][2] : themeColors["Default"][0]}
                      defaultLogos={defaultLogos}
                    />
                  </Route>
                  <Route exact path="/signup">
                    <SignUpPage picture={user.picture} email={user.email} logout={logout} />
                  </Route>
                  <Route exact path="/preferences">
                    <ProfilePage setUser={setUser} googleImage={googleImage} user={fetchedUser} />
                  </Route>
                  <Route exact path="/help">
                    <HelpPage user={fetchedUser} />
                  </Route>
                  <Route exact path="/message">
                    <MessagePage user={fetchedUser} />
                  </Route>
                  <Route path="/users">
                    <UsersPage style={{ display: "flex", flexDirection: "column" }} user={fetchedUser} defaultLogos={defaultLogos} />
                  </Route>
                </Switch>
              </div>
            </Content>
          </Layout>
        </div>
      </Router>
    );
  } else {
    return loginWithRedirect();
  }
}

export default App;
