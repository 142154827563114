import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { generateDashedButtonBackground, lightenDarkenColor } from "../../utils";

const { Panel } = Collapse;

function AddSectionPanel(props) {
  const borderColor = props.borderColor;
  const editMode = props.editMode;
  const setNewSectionModalVisible = props.setNewSectionModalVisible;

  const [addSectionKey, setAddSectionKey] = useState("0");
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  useEffect(() => {
    setAddSectionKey(editMode ? "1" : "0");
  }, [editMode]);

  return (
    <Collapse
      // style={{}}
      className="custom-actions"
      ghost
      accordion={false}
      bordered={false}
      activeKey={addSectionKey}
    >
      <Panel className="collapse-actions" showArrow={false} header={<></>} key="1">
        <div
          onMouseEnter={() => setBackgroundColor(lightenDarkenColor(borderColor, 150))}
          onMouseLeave={() => setBackgroundColor("transparent")}
          onClick={() => setNewSectionModalVisible(true)}
          style={{
            marginBottom: "10px",
            transition: "background-color 0.5s linear",
            cursor: "pointer",
            fontWeight: 600,
          }}
        >
          {generateDashedButtonBackground(
            borderColor,
            backgroundColor,
            "100%",
            50,
            "ADD SECTION",
            14,
            "50%",
            "50%",
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="plus"
              x={"calc(50% - 65px)"}
              y={17}
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <defs>
                <style></style>
              </defs>
              <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
              <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
            </svg>
          )}
        </div>
      </Panel>
    </Collapse>
  );
}

export { AddSectionPanel };
