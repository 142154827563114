/**
 * Component responsible for selecting themes
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

import { Card, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeUserPreferences,
  ChangeUserPreferencesInBulk,
} from "../../api/preferences";
import { colors, themeColors } from "../../colors";
import { selectTheme, setSections } from "../../store/filterSlice";
import { ThemeGrid } from "./ThemeGrid/ThemeGrid";

function EditThemes(props) {
  // Constants
  const user = props.user;
  const dispatch = useDispatch();
  const themeState = useSelector(selectTheme);
  const originalState = themeState.reduce(
    (themes, element) => ((themes[element.component] = element), themes),
    {}
  );

  // State variables
  const [defaultTheme, setDefaultTheme] = useState(originalState);
  const [loading, setLoading] = useState(false);

  // Handlers
  const cardStyle = {
    backgroundColor: themeColors[defaultTheme["Section Background"].color][2],
    borderRadius: "20px",
    height: "100%",
  };

  const headerStyle = {
    backgroundColor:
      defaultTheme["Section Header"].color == "Default"
        ? "WHITE"
        : colors[defaultTheme["Section Header"].color],
  };

  const saveChange = () => {
    setLoading(true);
    var changes = [];
    for (var component in defaultTheme) {
      changes.push(JSON.parse(JSON.stringify(defaultTheme[component])));
    }
    if (changes.length > 0) {
      message.loading("Saving...", 1000000);
      ChangeUserPreferencesInBulk(user.email, changes).then(() => {
        message.destroy();
        setLoading(false);
        message.destroy();
        message.success("Change saved!");
      });
    }
  };

  const updateAndSetTheme = () => {
    var theme = Object.entries(JSON.parse(JSON.stringify(defaultTheme))).map(
      function (component) {
        return component[1];
      }
    );
    let payload = { defaultTheme: theme };
    dispatch(setSections(payload));
    saveChange();
  };

  // Watchers
  useEffect(() => {
    if (JSON.stringify(originalState) != JSON.stringify(defaultTheme)) {
      updateAndSetTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTheme]);

  return (
    <Card
      // title={

      // }
      style={cardStyle}
      headStyle={headerStyle}
    >
      <h1
        style={{
          textAlign: "left",
          fontWeight: 700,
          fontSize: "20px",
          marginBottom: "32px",
          marginTop: "24px",
          marginLeft: "20px",
          color: themeColors[defaultTheme["Section Background"].color][3],
        }}
      >
        Choose Your Theme
      </h1>
      <ThemeGrid
        setDefaultTheme={setDefaultTheme}
        defaultTheme={defaultTheme}
        loading={loading}
      ></ThemeGrid>
    </Card>
  );
}

export { EditThemes };
