import { Button, Form, Input, message, Result, Select } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import { parseDomain, ParseResultType } from "parse-domain";
import { useEffect, useState } from "react";
import en from "world_countries_lists/data/countries/en/world.json";
import { FetchCompanies } from "../api/user";
import { generateLogo } from "../iconGenerator";
import { sortByName } from "../utils";

/**
 * Page of the sign up form
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function SignUpPage(props) {
  const email = props.email;
  const logout = props.logout;
  const picture = props.picture;

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState({ short: "LB" });

  useEffect(() => {
    var splitEmail = email.split("@");
    var parseResult = parseDomain(splitEmail[0]);
    if (companies.length != 0 && parseResult.type === ParseResultType.Listed) {
      const { _, domain, __ } = parseResult;

      var suggestedCompany = JSON.parse(JSON.stringify(companies)).filter((company) => {
        return company.name.toLowerCase() == domain;
      });
      setCompany(suggestedCompany[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, companies]);

  useEffect(() => {
    if (companies.length == 0) {
      FetchCompanies().then((res) => {
        setCompanies(res);
      });
    }
  }, [companies]);

  const handleChange = (value) => {
    setCompany(value);
  };

  const signUp = () => {
    if (firstName == "" || lastName == "" || company == "" || mobilePhoneNumber["phone"] == "" || mobilePhoneNumber["code"] == "") {
      message.destroy();
      message.error("Fill in all fields properly!");
    } else {
      SignUp(
        email,
        [firstName, lastName].join(" "),
        [mobilePhoneNumber["code"], mobilePhoneNumber["phone"]].join(" "),
        company,
        picture
      ).then(() => {
        message.success("Sign Up Successful!");
        window.location.reload(false);
        window.location.replace(window.location.href.replace("signup", ""));
      });
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 18 },
  };

  if (companies.length > 0 && (company == undefined || company == "")) {
    return (
      <Result
        status="error"
        title="Login Failed"
        subTitle={`Your account (${email}) doesn't seem to be registered. Please contact your company admin.`}
        extra={[
          <Button
            className="my-button"
            type="primary"
            key="console"
            onClick={logout}
            style={{
              width: 200,
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Go Back to Login
          </Button>,
        ]}
      />
    );
  } else if (companies.length > 0) {
    return (
      <div
        style={{
          padding: "4%",
          margin: "0 auto",
          width: "100%",
          justifyContent: "left",
        }}
      >
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <div style={{ width: 280, margin: "0 auto 30px" }}>{generateLogo("#1890ff", 500, 100, 200, 10, true)}</div>
        </div>
        <Form {...layout} name="control-ref">
          <Form.Item name="First Name" label="First Name" rules={[{ required: true }]}>
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={(v) => setFirstName(v.target.value)}
              style={{ borderRadius: "8px" }}
            />
          </Form.Item>
          <Form.Item name="Last Name" label="Last Name" rules={[{ required: true }]}>
            <Input placeholder="Last Name" value={lastName} onChange={(v) => setLastName(v.target.value)} style={{ borderRadius: "8px" }} />
          </Form.Item>
          <Form.Item name="company" label="Company" rules={[{ required: true }]}>
            <Select
              defaultValue={companies.length != 0 ? companies[0].id : undefined}
              onChange={handleChange}
              // disabled
              value={company}
              style={{ borderRadius: "8px" }}
            >
              {companies.map((company) => {
                return (
                  <Select.Option value={company.id} key={company.id}>
                    {company.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="mobilePhoneNumber" label="Mobile Phone Number">
            <ConfigProvider locale={en} areaSorter={(a, b) => sortByName(a, b)}>
              <CountryPhoneInput
                value={mobilePhoneNumber}
                style={{ borderRadius: "8px" }}
                onChange={(v) => {
                  setMobilePhoneNumber(v);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={signUp}
              className="my-button"
              style={{
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  } else {
    return <></>;
  }
}

export { SignUpPage };
