import { axiosURL } from "../config/axios";

const ChangeUserPreferences = (email, component, color) => {
  let form = new FormData();
  form.append("email", email);
  form.append("component", component);
  form.append("color", color);
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/personalization/preference/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const ChangeUserPreferencesInBulk = (email, components) => {
  let form = {};
  form["email"] = email;
  form["customizations"] = components;
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/personalization/preference/", form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

export { ChangeUserPreferences, ChangeUserPreferencesInBulk };
