import { CloseCircleOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { Card, message, Skeleton, Tooltip, Typography } from "antd";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { DeleteApp, DeleteSection, GetSections } from "../../../api/bookmark";
import "../../../App.css";
import { colors, themeColors } from "../../../colors";
import { generateCircularIcon, generateIcon } from "../../../iconGenerator";
import { selectEditMode } from "../../../store/editAppsSlice";
import { deleteSection, setSections } from "../../../store/filterSlice";
import { isValidHttpUrl } from "../../../utils";
import { CustomModal } from "../../Modal/CustomModal";
import { AppGrid } from "./AppGrid/AppGrid";

/**
 * Draggable section containing the apps
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function AppSection(props) {
  const draggable = props.draggable;
  const index = props.index;
  const section = props.section;
  const sectionsLoading = props.loading;
  const setDraggable = props.setDraggable;
  const setNewTitle = props.setNewTitle;
  const user = props.user;

  const [addable, setAddable] = useState(false);
  const [cancelActive, setCancelActive] = useState(true);
  const [color, setColor] = useState(section.color);
  const [deletable, setDeletable] = useState(false);
  const [editable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

  const dispatch = useDispatch();
  const editMode = useSelector(selectEditMode);

  const gridStyle = {
    // boxShadow: "0 1px 46px -4px rgb(0 0 0 / 28%)",
    // opacity: 0.8,
    backgroundColor: themeColors[color][2] != undefined ? themeColors[color][2] : colors["WHITE"],
    borderColor: themeColors[color][2] != undefined ? themeColors[color][2] : colors["WHITE"],
    borderRadius: "16px",
    // marginTop: "15px",
    width: "100%",
    overflow: "hidden",
    transition: "all 2s ease-in-out",
  };

  const openAppsButtonStyle = {
    marginLeft: "15px",
    fontSize: "20px",
  };

  const editTitle = (title) => {
    if (section.name != title) {
      section.name = title;
      setNewTitle(section.id, title);
    }
  };

  const removeSection = () => {
    setLoading(true);
    setCancelActive(false);
    DeleteSection(section.id).then((res) => {
      dispatch(deleteSection({ sectionId: section.id }));
      setModalDeleteVisible(false);
      setLoading(false);
      setCancelActive(true);
      message.success("Section deleted successfully!");
    });
  };

  const DeleteAllApps = () => {
    let promises = [];
    for (let appIndex in section.apps) {
      promises.push(DeleteApp(section.apps[appIndex].id));
    }
    message.loading("Deleting...", 1000000);
    Promise.all(promises).then(() => {
      GetSections(user.email).then((sections) => {
        message.destroy();
        message.success("Delete SuccessFull!");
        dispatch(setSections({ sections: sections }));
        setDeletable(false);
      });
    });
  };

  useEffect(() => {
    setColor(section.color);
  }, [section]);

  useEffect(() => {
    if (!editMode) {
      setAddable(false);
    }
  }, [editMode]);

  return (
    <Card
      key={"card" + index.toString()}
      loading={false}
      title={
        <Skeleton loading={sectionsLoading} active>
          <Typography.Text
            className="text"
            style={{
              // marginLeft: "1%",
              color: themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"],
            }}
            editable={
              editMode
                ? {
                    onChange: editTitle,
                    onEnd: () => console.log("here"),
                    enterIcon: false,
                    maxLength: 50,
                    icon: (
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        {generateCircularIcon(
                          "pen",
                          30,
                          themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"],
                          "16",
                          "16"
                        )}
                      </div>
                    ),
                    tooltip: "Edit title",
                    autoSize: true,
                  }
                : false
            }
          >
            {section.name}
          </Typography.Text>
        </Skeleton>
      }
      headStyle={{
        fontSize: "25px",
        textAlign: "initial",
        maxHeight: "48px",
        borderBottom: "0px",
      }}
      style={gridStyle}
      extra={
        editMode && !sectionsLoading
          ? [
              <Tooltip placement="top" title="Delete Section" style={{ padding: "0%" }}>
                <div
                  onClick={() => setModalDeleteVisible(true)}
                  style={{
                    color: "#FF5A5A",
                    padding: "0%",
                    cursor: "pointer",
                    animation: "fadeIn 0.7s ease-in-out",
                  }}
                >
                  {generateIcon("bin", 20, 20, themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"])}
                </div>
              </Tooltip>,
            ]
          : !sectionsLoading
          ? [
              <Tooltip placement="top" title="Open all applications" style={{ padding: "0%" }}>
                <FolderOpenOutlined style={openAppsButtonStyle} onClick={() => OpenSectionApps(props.section.apps)} />
              </Tooltip>,
            ]
          : []
      }
    >
      <CustomModal
        title=""
        centered
        color={themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"]}
        icon={<CloseCircleOutlined></CloseCircleOutlined>}
        visible={modalDeleteVisible || deletable}
        id={`modaldelete${section.id}`}
        closable={cancelActive}
        loading={loading}
        cancelButtonProps={{ disabled: !cancelActive }}
        onOk={deletable ? DeleteAllApps : removeSection}
        onCancel={() => {
          setModalDeleteVisible(false);
          setDeletable(false);
        }}
        innerText={
          deletable ? (
            "Are you sure you want to delete the section apps?"
          ) : (
            <h2
              style={{
                marginTop: "5%",
                fontWeight: 700,
                fontSize: "20px",
                color: themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"],
              }}
            >
              Are you sure you want to delete this section: {section.name}?
            </h2>
          )
        }
      ></CustomModal>
      <div onMouseEnter={() => setDraggable(false)} onMouseLeave={() => setDraggable(true)}>
        <AppGrid
          defaultLogos={props.defaultLogos}
          loading={sectionsLoading}
          sectionId={section.id}
          user={user}
          setAddable={setAddable}
          key={"grid" + index}
          editable={editable}
          addable={addable}
          deletable={editMode}
          color={themeColors[color][3] != undefined ? themeColors[color][3] : colors["WHITE"]}
          apps={section.apps}
        />
      </div>
    </Card>
  );
}

export { AppSection };

function OpenSectionApps(apps) {
  let windowsOpened = [];
  let appListCopy = [...apps];
  const browserIsFirefox = typeof InstallTrigger !== "undefined";
  const tabsOpenInReverse = browserIsFirefox || isMobile;
  if (tabsOpenInReverse) {
    appListCopy.reverse();
  }
  for (let app of appListCopy) {
    const urlToOpen = isValidHttpUrl(app.url) ? app.url : `http://${app.url}`;
    const newWindow = window.open(urlToOpen);
    windowsOpened.push(newWindow);
    if (!newWindow || newWindow.closed || typeof newWindow.closed == "undefined") {
      // Window open blocked
      message.warning("Hello! Please allow pop-ups for this feature to work properly.", 6);
      // Close partially opened windows
      for (let openedWindow of windowsOpened) {
        openedWindow?.close();
      }
      break;
    }
  }
}
