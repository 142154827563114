import { Switch, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectEditMode, selectIsSearchTextEmpty as selectSearchTextEmpty, selectTextColor, setEditMode } from "../../store/editAppsSlice";
import { isDefault } from "../../store/filterSlice";

export default function EditAppsToggle() {
  const dispatch = useDispatch();
  const editMode = useSelector(selectEditMode);
  const textColor = useSelector(selectTextColor);
  const loading = useSelector(isDefault);
  const isSearchTextEmpty = useSelector(selectSearchTextEmpty);

  return (
    <Tooltip
      title={
        isSearchTextEmpty
          ? !loading
            ? "Clear search text to turn on edit mode"
            : "Still loading"
          : `Switch edit mode ${editMode ? "off" : "on"}`
      }
    >
      <div
        style={{
          display: "inline-block",
          marginRight: "9px",
          marginBottom: "3px",
          fontSize: "14px",
          fontWeight: 400,
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: textColor,
            paddingRight:"10px"
          }}
          className="welcome-visible"
        >
          {editMode ? "Save edits" : "Edit mode"}
        </div>
        <Switch
          onChange={(checked) => dispatch(setEditMode(checked))}
          checked={editMode}
          disabled={isSearchTextEmpty}
          style={{
            alignItems: "left",
            backgroundColor: editMode ? textColor : "#acb0b9",
          }}
        ></Switch>
      </div>
    </Tooltip>
  );
}
