import { createSlice } from "@reduxjs/toolkit";
import { dynamicSort } from "../utils";

export const loadingUsers = [
  {
    id: 0,
    name: "",
    role: "",
  },
  {
    id: 1,
    name: "",
    role: "",
  },
  {
    id: 2,
    name: "",
    role: "",
  },
  {
    id: 3,
    name: "",
    role: "",
  },
];

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      userList: loadingUsers,
      searchValue: "",
      sortValue: [],
      companyFilterValue: "",
      roleFilterValue: "",
    },
  },
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setUserList: (state, action) => {
      state.value.userList = action.payload.userList;
      state.value = state.value;
    },
    setSortByValue: (state, action) => {
      state.value.sortValue = action.payload.sortValue;
      state.value = state.value;
    },
    setCompanyFilterValue: (state, action) => {
      state.value.companyFilterValue = action.payload.companyFilter;
      state.searchValue = state.searchValue;
    },
    setRoleFilterValue: (state, action) => {
      state.value.roleFilterValue = action.payload.roleFilter;
      state.searchValue = state.searchValue;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserList } = userSlice.actions;
export const { setSearchValue } = userSlice.actions;
export const { setSortByValue } = userSlice.actions;
export const { setCompanyFilterValue } = userSlice.actions;
export const { setRoleFilterValue } = userSlice.actions;

export const selectUserFilter = (state) => getUsers(state);

const getUsers = (state) => {
  var users = JSON.parse(JSON.stringify(state.user.value.userList));

  if (
    state.user.value.companyFilterValue != undefined &&
    state.user.value.companyFilterValue != ""
  ) {
    users = users.filter((user) => {
      return user.companyId == state.user.value.companyFilterValue;
    });
  }
  if (
    state.user.value.roleFilterValue != undefined &&
    state.user.value.roleFilterValue != ""
  ) {
    users = users.filter((user) => {
      return user.role == state.user.value.roleFilterValue;
    });
  }
  var filteredUsers = [];
  if (
    typeof state.user &&
    state.user.searchValue &&
    state.user.searchValue.searchValue.length != 0
  ) {
    var filter = state.user.searchValue.searchValue;

    var filteredUsers = [];
    for (var i = 0; i < users.length; i++) {
      var user = users[i];
      if (
        user.name.toLowerCase().includes(filter.toLowerCase()) ||
        user.role.toLowerCase().includes(filter.toLowerCase()) ||
        user.companyName.toLowerCase().includes(filter.toLowerCase())
      ) {
        filteredUsers.push(user);
      }
    }
  } else {
    filteredUsers = users;
  }
  if (
    typeof state.user &&
    state.user.value &&
    state.user.value.sortValue.length != 0
  ) {
    filteredUsers.sort(dynamicSort(state.user.value.sortValue[0]));
    if (!state.user.value.sortValue[1]) filteredUsers = filteredUsers.reverse();
  }
  return filteredUsers;
};

export default userSlice.reducer;
