/*
 * @description Page to send messages to other users
 * @version 1.0.1
 * @author Hafez Jawhary
 */

import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { message, Select, Form, Button, Row, Col } from "antd";
import { SendSingleNotification } from "../api/notifications";
import { FetchAllUsers } from "../api/user";
import { useSelector } from "react-redux";
import { selectTheme } from "../store/filterSlice";
import { themeColors } from "../colors";
import BreadCrumb from "../components/utils/BreadCrumb";
import { generateIcon } from "../iconGenerator";
import { lightenDarkenColor } from "../utils";

function MessagePage(props) {
  // page constants
  const user = props.user.user;
  const theme = useSelector(selectTheme);

  // state variables
  const [notificationMessage, setNotificationMessage] = useState("");
  const [buttonColor, setButtonColor] = useState(themeColors["Default"][0]);
  const [form] = Form.useForm();
  const [textColor, setTextColor] = useState(themeColors["Default"][2]);
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  // Handlers
  const clearFields = () => {
    form.resetFields();
    setNotificationMessage("");
    setSelectedRecipients([]);
  };

  const sendMessage = () => {
    if (selectedRecipients.length < 1) {
      message.destroy();
      message.error("Select At Least One User!");
    } else if (
      notificationMessage == "" ||
      notificationMessage.replace(/\s/g, "") == ""
    ) {
      message.destroy();
      message.error("No Message Sent!");
    } else {
      message.loading(`Sending Message...`, 1000000);
      SendSingleNotification(
        selectedRecipients.join(","),
        notificationMessage
      ).then((res) => {
        message.destroy();
        if (res.status != 200) {
          message.destroy();
          message.error(`Failed to send message!`);
        } else {
          clearFields();
          message.success(`Message sent!`);
        }
      });
    }
  };

  const handleChange = (value) => {
    setSelectedRecipients(value);
  };

  useEffect(() => {
    for (var i in theme) {
      if (theme[i].component == "Nav Bar")
        setButtonColor(themeColors[theme[i].color][0]);
      else if (theme[i].component == "Section Header")
        setTextColor(themeColors[theme[i].color][2]);
    }
  }, [theme]);

  useEffect(() => {
    if (JSON.stringify(recipients) == JSON.stringify([])) {
      FetchAllUsers(user.email).then((res) => {
        let tempRecipientList = [...res, JSON.parse(JSON.stringify(user))];
        // Add company name to all users
        tempRecipientList.forEach((element) => {
          element["name"] = element["name"] + " - " + element["companyName"];
        });
        setRecipients(tempRecipientList);
      });
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        color={buttonColor}
        style={{
          justifyContent: "left",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      />
      <Form
        name="basic"
        form={form}
        labelAlign={"left"}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 30 }}
        autoComplete="off"
        style={{ marginTop: "32px" }}
      >
        <Form.Item
          style={{ padding: "1%", marginBottom: "0px" }}
          label="To:"
          name="To"
        >
          <Select
            value={selectedRecipients}
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            mode="multiple"
            style={{
              width: "100%",
              textAlign: "left",
              marginBottom: "0px",
              borderRadius: "8px",
              color: buttonColor,
              backgroundColor: textColor,
              border: `1px solid ${lightenDarkenColor(buttonColor, 120)}`,
            }}
            placeholder="Please select"
            onChange={handleChange}
          >
            {recipients.map((recipient) => {
              return (
                <Select.Option
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    color: buttonColor,
                  }}
                  value={recipient.id}
                  key={recipient.id}
                >
                  {recipient.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item style={{ padding: "1%" }} label="Message:" name="message">
          <TextArea
            value={notificationMessage}
            rows={10}
            placeholder="Enter text message"
            onChange={(value) => setNotificationMessage(value.target.value)}
            autoSize={{ minRows: 10 }}
            style={{
              color: buttonColor,
              backgroundColor: textColor,
              borderRadius: "8px",
              border: `1px solid ${lightenDarkenColor(buttonColor, 120)}`,
            }}
          ></TextArea>
        </Form.Item>
        <Form.Item style={{ padding: "1%" }}>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              onClick={sendMessage}
              style={{
                backgroundColor: buttonColor,
                color: textColor,
                height: "48px",
                fontSize: "14px",
                fontWeight: 600,
                width: "117px",
                lineHeight: "14px",
                borderColor: buttonColor,
                borderRadius: "24px",
              }}
              icon={
                <Row
                  justify="center"
                  style={{
                    height: "16px",
                  }}
                >
                  <Col style={{ marginRight: "8px" }}>
                    {generateIcon("send", "13", "16", textColor, "0", "50")}
                  </Col>
                  <Col
                    style={{
                      verticalAlign: "bottom",
                      fontSize: "14px",
                      fontWeight: 600,
                      paddingTop: "1px",
                    }}
                  >
                    SEND
                  </Col>
                </Row>
              }
            ></Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}

export { MessagePage };
