import { CloseCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Menu, message, Tooltip, Typography } from "antd";
import { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteApp } from "../../../../api/bookmark";
import { colors } from "../../../../colors";
import { generateCircularIcon } from "../../../../iconGenerator";
import { deleteApp } from "../../../../store/filterSlice";
import { createImageFromInitials, lightenDarkenColor } from "../../../../utils";
import { AppModal } from "../../../Modal/AppModal";
import { CustomModal } from "../../../Modal/CustomModal";

const { Paragraph, _ } = Typography;

/**
 * draggable app button
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function AppIcon(props) {
  const deletable = props.deletable;
  const email = props.email;
  const setEditting = props.setEditting;
  const color = props.color;
  const defaultLogos = props.defaultLogos;

  const ref = createRef();

  const [app, setApp] = useState(props.app);
  const [editable, setEditable] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hoverDetails, setHoverDetails] = useState(
    app.tags
      .map((tag) => {
        return tag[0];
      })
      .join(", ")
  );

  useEffect(() => {
    setApp(props.app);
  }, [props.app]);

  useEffect(() => {
    setEditable(isModalVisible || popConfirmVisible);
    setEditting(isModalVisible || popConfirmVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, popConfirmVisible]);

  useEffect(() => {
    setHoverDetails(
      app.tags
        .map((tag) => {
          return tag[0];
        })
        .join(", ")
    );
  }, [app.tags]);

  const dispatch = useDispatch();

  const onClick = () => {
    if (!editable && !deletable) {
      if (!app.url.match(/^https?:\/\//i)) {
        app.url = "http://" + app.url;
      }
      window.open(app.url, "_blank").focus();
    }
  };

  const confirmDelete = () => {
    message.loading("Deleting app...", 1000000);
    DeleteApp(app.id).then(() => {
      dispatch(deleteApp({ app: app }));
      message.destroy();
      message.success("App deleted successfully");
    });
  };

  const badgeIcon = {
    // animation: deletable ? "shake" : "none",
    // animationDuration: deletable ? "1s" : "none",
    // animationIterationCount: deletable ? "infinite" : "none",
    margin: "16px auto auto auto",
    position: "relative",
  };

  const iconStyle = {
    // boxShadow: "0 4px 8px 8px rgba(0, 0, 0, 0.1)",
    border: deletable ? `1px solid ${lightenDarkenColor(color, 150)}` : `1px solid ${lightenDarkenColor(color, 150)}`,
    borderRadius: "34px",
    // padding: "10%",
    // paddingTop: "0%",
    height: "68px",
    width: "68px",
    backgroundColor: "white",
    margin: "0% 0% 0% 0%",
    alignItems: "bottom",
    cursor: !deletable ? "pointer" : null,
  };

  const menu = (
    <Menu
      style={{
        borderRadius: "12px",
        width: "100px",
        border: `1px solid ${lightenDarkenColor(color, 25)}`,
      }}
    >
      <Menu.Item
        style={{
          fontWeight: 400,
          fontSize: "14px",
          color: lightenDarkenColor(color, 25),
        }}
        icon={<EditOutlined></EditOutlined>}
        onClick={() => {
          setIsModalVisible(true);
        }}
        key="1"
      >
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined></DeleteOutlined>} onClick={setPopConfirmVisible} danger key="2">
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        width: 106,
        height: 126,
        position: "relative",
        margin: "auto",
        borderRadius: "14px",
        backgroundColor: deletable ? lightenDarkenColor(color, 140) : "transparent",
        border: deletable ? `2px solid ${lightenDarkenColor(color, 160)}` : "2px solid transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "background-color 0.2s linear",
      }}
    >
      <Badge
        count={
          deletable ? (
            <div
              style={{
                display: "flex",
                animation: "fadeIn 0.7s ease-in-out",
                transition: "background-color 0.5s linear",
                cursor: "pointer",
              }}
            >
              <Dropdown display="inherit" overlay={deletable ? menu : <></>} trigger={["click"]}>
                {generateCircularIcon("options", 28, lightenDarkenColor(color, 25), "4", "16")}
              </Dropdown>
            </div>
          ) : (
            <></>
          )
        }
        offset={[-13, 13]}
      >
        <div style={badgeIcon}>
          <Tooltip
            mouseEnterDelay={deletable ? "10000" : "0.5"}
            style={{ display: deletable ? "none" : "block" }}
            placement="bottom"
            title={
              <span>
                <div style={{ fontFamily: "Poppins", fontSize: "13px" }}>
                  <h3 style={{ display: "inline-block", color: "#FFFFFF" }}>Url: </h3> {app.url}
                </div>
                {app.tags.length > 0 ? (
                  <div style={{ fontFamily: "Poppins", fontSize: "13px" }}>
                    <h3 style={{ marginTop: "2px", color: "#FFFFFF" }}>Tags: </h3> {hoverDetails}
                  </div>
                ) : (
                  <></>
                )}
              </span>
            }
          >
            <img
              draggable="false"
              disabled={deletable}
              type="image"
              style={iconStyle}
              src={app.logo}
              onClick={onClick}
              onError={({ currentTarget }) => {
                var newImage = createImageFromInitials(64, app.name, colors["PURPLE"]);
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = newImage;
              }}
            ></img>
          </Tooltip>
          {/* </Badge> */}
        </div>
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            textAlign: "center",
            animation: "none",
            textOverflow: "ellipsis-word",
            marginTop: "10px",
            color: color,
            // height: "100%",
            lineHeight: 1.1,
            hyphens: "auto",
            wordBreak: "break-word",
            width: "100%",
            maxHeight: "auto",
            minHeight: "20px",
            fontWeight: 400,
            fontSize: "13px",
          }}
        >
          {app.name}
        </Paragraph>
        {isModalVisible ? (
          <AppModal
            color={color}
            setApp={setApp}
            email={email}
            name={app.name}
            logo={app.logo}
            url={app.url}
            tags={app.tags}
            isNew={false}
            id={app.id}
            isModalVisible={true}
            defaultLogos={defaultLogos}
            setIsModalVisible={setIsModalVisible}
          ></AppModal>
        ) : (
          <></>
        )}
      </Badge>
      <CustomModal
        color={color}
        icon={<CloseCircleOutlined></CloseCircleOutlined>}
        visible={popConfirmVisible}
        onOk={confirmDelete}
        onCancel={() => {
          setPopConfirmVisible(false);
        }}
        innerText={
          <div>
            <div style={{ margin: "auto", display: "flex" }}>
              <img
                draggable="false"
                disabled={deletable}
                type="image"
                style={Object.assign(iconStyle, { margin: "auto" })}
                src={app.logo}
                onError={({ currentTarget }) => {
                  var newImage = createImageFromInitials(64, app.name, colors["PURPLE"]);
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = newImage;
                }}
              ></img>
            </div>
            <h2
              style={{
                marginTop: "5%",
                fontWeight: 700,
                fontSize: "20px",
                color: color,
              }}
            >
              Are you sure you want to delete this app: {app.name}?
            </h2>
          </div>
        }
      ></CustomModal>
    </div>
  );
}

export { AppIcon };
