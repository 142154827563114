import { Collapse, List, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { lightenDarkenColor } from "../../utils";
const { Panel } = Collapse;

/**
 * Select menu created from collapse (customized)
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function CollapseableSelect(props) {
  const data = props.data;
  const color = props.color;
  const selectValueParent = props.selectValueParent;
  const placeholder = props.placeholder;
  const disabled = props.disabled;

  const [value, setValue] = useState("0");
  const [selectValue, setSelectValue] = useState("");
  const [selectedValueDisplay, setSelectedValueDisplay] = useState("");
  const [collapsable, setCollapsable] = useState("disabled");

  const box = useRef();

  useEffect(() => {
    setCollapsable(disabled ? "disabled" : "");
  }, [disabled]);

  useEffect(() => {
    selectValueParent(selectValue);
  }, [selectValue]);

  const handleOutsideClick = (event) => {
    if (box && box.current && !box.current.contains(event.target)) {
      alert("you just clicked outside of box!");
    }
  };

  useEffect(() => {
    // Adding a click event listener
    document.addEventListener("click", handleOutsideClick);
  }, [box]);

  return (
    // <div
    //   style={{
    //     height: "auto",
    //     borderRadius: "24px",
    //     border: `1px solid ${"black"}`,
    //   }}
    // >
    <Collapse
      ref={box}
      expandIconPosition="right"
      style={{
        width: "95%",
        borderRadius: "24px",
        overflow: "visible !important",
        backgroundColor: "white",
        transition: "opacity 2s ease, border-color 1s ease",
        border: `1px solid ${
          selectValue != "" ? color : lightenDarkenColor(color, 120)
        }`,
        opacity: 1,
        position: "relative",
        zIndex: 2,
      }}
      bordered={false}
      collapsible={collapsable}
      defaultActiveKey={[value]}
      activeKey={value}
      onChange={() => setValue(value == "0" ? "1" : "0")}
    >
      <Panel
        extra={
          selectedValueDisplay != "" ? (
            <CloseCircleFilled
              onClick={() => {
                setSelectValue("");
                setSelectedValueDisplay("");
                setCollapsable("");
              }}
              onMouseEnter={(e) => {
                setCollapsable("disabled");
              }}
              onMouseLeave={(e) => {
                setCollapsable("");
              }}
              style={{
                color: lightenDarkenColor(color, 60),
                cursor: "pointer",
                animation: "fadeIn 0.4s ease-in-out",
              }}
            ></CloseCircleFilled>
          ) : (
            <></>
          )
        }
        header={
          <Typography.Text
            ellipsis={true}
            style={{
              textOverflow: "ellipsis",
              color:
                selectedValueDisplay != ""
                  ? color
                  : lightenDarkenColor(color, 120),
              fontSize: "14px",
              height: "24px",
              fontWeight: 400,
            }}
          >
            {selectedValueDisplay != "" ? selectedValueDisplay : placeholder}
          </Typography.Text>
        }
        style={{ position: "relative", zIndex: 0, opacity: 0.2 }}
        key="1"
      >
        {/* {JSON.stringify(data)} */}
        {data != undefined ? (
          <List style={{ paddingBottom: "20px" }}>
            {Object.keys(data).map((key) => (
              <>
                {key == "" ? (
                  <></>
                ) : (
                  <List.Item
                    style={{
                      marginTop: 0,
                      fontSize: "12px",
                      marginBottom: 6,
                      color: lightenDarkenColor(color, 120),
                      borderBottom: "0px",
                      padding:
                        Object.keys(data).indexOf(key) == "0"
                          ? "0px 0px 0px 20px"
                          : "12px 0px 0px 20px",
                      fontWeight: 400,
                    }}
                  >
                    {key}
                  </List.Item>
                )}
                {Object.keys(data[key]).map((key1) => (
                  <List.Item
                    onClick={(e) => {
                      // e.target.style.backgroundColor = "transparent";
                      setSelectValue(
                        JSON.stringify(selectValue) ==
                          JSON.stringify(data[key][key1])
                          ? ""
                          : data[key][key1]
                      );
                      setSelectedValueDisplay(
                        selectedValueDisplay == key1 ? "" : key1
                      );
                      setValue("0");
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = lightenDarkenColor(
                        color,
                        150
                      );
                    }}
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "transparent")
                    }
                    style={{
                      cursor: "pointer",
                      fontWeight: 500,
                      fontSize: "14px",
                      width: "100%",
                      color: color,
                      borderBottom: "0px",
                      padding: "6px 0px 6px 20px",
                      // textShadow: "0px 0px black",
                      // backgroundColor:
                      // selectedValueDisplay == key1
                      //   ? lightenDarkenColor(color, 120)
                      //   : "transparent",
                    }}
                  >
                    {key1}
                  </List.Item>
                ))}
              </>
            ))}
          </List>
        ) : (
          <></>
        )}
      </Panel>
    </Collapse>
    // </div>
  );
}

export { CollapseableSelect };
