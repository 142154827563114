import {
  Card,
  Avatar,
  Badge,
  Tooltip,
  Menu,
  Dropdown,
  message,
  Row,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/filterSlice";
import { themeColors } from "../../colors";
import { useState } from "react";
import Text from "antd/lib/typography/Text";
import { ProfileModal } from "../Modal/ProfileModal";
import { ImageModal } from "../Modal/ImageModal";
import { EditUser } from "../../api/user";
import { generateCircularIcon, generateIcon } from "../../iconGenerator";

/**
 * Component responsible for profile info (viewing, editting)
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function ProfileInfo(props) {
  const googleImage = props.googleImage;
  const setUser = props.setUser;
  const user = props.user;
  const color = props.color;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [editType, setEditType] = useState("name");

  const defaultTheme = useSelector(selectTheme);
  const cardColor = themeColors[defaultTheme[1].color][2];

  const iconStyle = {
    // boxShadow: "0 4px 8px 8px rgba(0, 0, 0, 0.1)",
  };

  const cardStyle = {
    backgroundColor: cardColor,
    height: "100%",
    borderRadius: "20px",
    padding: "5%",
    boxShadow: "0px 2px 8px rgba(156, 181, 196, 0.05)",
  };

  const editName = () => {
    setEditType("name");
    setIsModalVisible(true);
  };

  const editPhone = () => {
    setEditType("phone");
    setIsModalVisible(true);
  };

  const setDefaultImage = () => {
    message.loading("Updating...", 1000000);
    var newUser = JSON.parse(JSON.stringify(user));
    newUser.image = googleImage;
    EditUser(newUser).then(() => {
      setUser(newUser);
      message.destroy();
      message.success("Image changed successfully!");
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={setUploadImageModal} key="h00">
        Add Custom Image
      </Menu.Item>
      <Menu.Item
        key="h04"
        onClick={setDefaultImage}
        disabled={user.image == googleImage}
      >
        Set Default Image
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      style={cardStyle}
      headStyle={{ height: "0px", maxHeight: "0px" }}
      cover={
        <div style={{ paddingTop: "-10px" }}>
          <Row justify="end">
            <Tooltip
              placement="top"
              title="Edit Profile"
              style={{ padding: "0%" }}
            >
              {" "}
              <div
                onClick={editName}
                style={{
                  display: "inline-block",
                  color: "#FF5A5A",
                  padding: "0%",
                  cursor: "pointer",
                  animation: "fadeIn 0.7s ease-in-out",
                }}
              >
                <div style={{ display: "flex" }}>
                  {generateCircularIcon("pen", 30, color, "16", "16")}
                </div>
              </div>
            </Tooltip>
          </Row>
          <Badge
            offset={[-15, 135]}
            status="default"
            count={
              <div style={{ display: "flex", cursor: "pointer" }}>
                <Tooltip title={"Edit Photo"}>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    {generateCircularIcon("camera", 36, color, 18, 15)}
                  </Dropdown>
                </Tooltip>
              </div>
            }
          >
            {user.image == undefined ? (
              <Avatar size={160} icon={<UserOutlined />} />
            ) : (
              <Avatar style={iconStyle} size={160} src={user.image} />
            )}
          </Badge>
        </div>
      }
    >
      <div style={{ fontSize: "18px", marginTop: "24px" }}>
        <Text
          ellipsis={true}
          style={{
            textOverflow: "ellipsis",
            fontSize: "24px",
            color: color,
            fontWeight: 700,
          }}
        >
          {user.name}
        </Text>
        <div
          style={{
            width: "100%",
            color: color,
            marginTop: "24px",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          <div
            style={{ textAlign: "left", lineHeight: "21px", display: "block" }}
          >
            <div
              style={{
                display: "inline-block",
                height: "16px",
                verticalAlign: "middle",
              }}
            >
              {generateIcon("phone", 25, 16, color)}
            </div>
            {user.phone == undefined ? (
              <Text
                italic
                style={{
                  verticalAlign: "middle",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                }}
              >
                <Text style={{ opacity: 0.5 }}>(no phone number)</Text>
              </Text>
            ) : (
              <Text
                style={{
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  margin: "auto",
                  color: color,
                }}
              >
                {" "}
                +{user.phone}
              </Text>
            )}
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            lineHeight: "21px",
            display: "block",
            marginTop: "16px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              height: "16px",
              verticalAlign: "middle",
            }}
          >
            {generateIcon("mail", 30, 25, color)}
          </div>
          <Text
            ellipsis={true}
            style={{
              textOverflow: "ellipsis-word",
              fontSize: "14px",
              fontWeight: 400,
              overflow: "hidden",
              display: "inline-block",
              color: color,
            }}
          >
            {user.email}
          </Text>
        </div>
        <div
          style={{
            textAlign: "left",
            lineHeight: "21px",
            display: "block",
            marginTop: "16px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              height: "16px",
              verticalAlign: "middle",
            }}
          >
            {generateIcon("company", 28, 16, color)}
          </div>
          <Text
            ellipsis={true}
            style={{
              textOverflow: "ellipsis-word",
              fontSize: "14px",
              fontWeight: 400,
              overflow: "hidden",
              display: "inline-block",
              color: color,
            }}
          >
            {user.companyName}
          </Text>
        </div>
      </div>
      <ProfileModal
        user={user}
        color={color}
        editType={editType}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setUser={setUser}
      ></ProfileModal>
      <ImageModal
        color={color}
        user={user}
        setUser={setUser}
        isModalVisible={uploadImageModal}
        setIsModalVisible={setUploadImageModal}
      ></ImageModal>
    </Card>
  );
}

// editable={{ onChange: setEditableStr }}

export { ProfileInfo };
