import { axiosURL } from "../config/axios";

const FetchNotifications = (email) => {
  return new Promise((resolve, reject) => {
    axiosURL
      .get("/notifications/notification", { params: { email: email } })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

const SendIssue = (email, issue) => {
  let form = new FormData();
  form.append("email", email);
  form.append("message", issue);
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/notifications/help/", form)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err.message);
        resolve(err);
      });
  });
};

const SendSingleNotification = (id, message) => {
  let form = new FormData();
  form.append("recipients", id);
  form.append("message", message);
  return new Promise((resolve, reject) => {
    axiosURL
      .post("/notifications/notification/", form)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err.message);
        resolve(err);
      });
  });
};

const SetAsRead = (notifications) => {
  let form = { id: notifications };
  return new Promise((resolve, reject) => {
    axiosURL
      .put("/notifications/notification/", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};

export { FetchNotifications, SetAsRead, SendIssue, SendSingleNotification };
