import { message } from "antd";
import { ConfigProvider, CountryPhoneInput, defaultAreas } from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import Modal from "antd/lib/modal/Modal";
import { createRef, useEffect, useState } from "react";
import en from "world_countries_lists/data/countries/en/world.json";
import { EditUser } from "../../api/user";
import { lightenDarkenColor, sortByName } from "../../utils";
import { ModalInput } from "../utils/ModalInput";

const areas = defaultAreas;

/**
 * Modal used to edit profile info (name, phone number...)
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function ProfileModal(props) {
  const editType = props.editType;
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const setUser = props.setUser;
  const user = props.user;
  const color = props.color;

  const [loading, setLoading] = useState(false);
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState({ short: "LB" });
  const [newName, setName] = useState("");

  const nameRef = createRef(null);
  const numberRef = createRef(null);

  useEffect(() => {
    if (isModalVisible) {
      setName(user.name != undefined ? user.name : "");
      setMobilePhoneNumber(
        user.phone != undefined
          ? {
              short: areas.filter((area) => {
                return area.phoneCode == user.phone.split(" ")[0];
              })[0].short,
              phone: user.phone.split(" ")[1],
              code: user.phone.split(" ")[0],
            }
          : { short: "LB" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    if (nameRef.current != undefined && editType == "name") nameRef.current.focus();
    // if (numberRef.current != undefined && editType == "phone")

    // numberRef.current.focus();
  }, [nameRef, numberRef]);

  const clearFields = () => {
    setName("");
    setMobilePhoneNumber({ code: "LB" });
  };

  const editInfo = () => {
    var newPhone = [
      mobilePhoneNumber["code"],
      mobilePhoneNumber["phone"].charAt(0) != "0" ? mobilePhoneNumber["phone"] : mobilePhoneNumber["phone"].slice(1),
    ].join(" ");
    if (newPhone == user.phone && newName == user.name) {
      message.destroy();
      message.error(`Nothing to be edited!`);
    } else if (newPhone.split(" ").length < 2 || newName.length == 0) {
      message.destroy();
      message.error(`Name or phone number missing!`);
    } else {
      setLoading(true);
      user.name = newName;
      user.phone = newPhone;
      EditUser(user).then(() => {
        setLoading(false);
        setIsModalVisible(false);
        setUser(JSON.parse(JSON.stringify(user)));
        clearFields();
        message.success(`Profile edited!`);
      });
    }
  };

  const onPressEnter = () => {
    if (!loading) editInfo();
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={editInfo}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
      style={{
        border: `1px solid ${color}`,
        borderRadius: "8px",
      }}
      okButtonProps={{
        loading: loading,
        style: { backgroundColor: color, color: "white" },
      }}
      cancelButtonProps={{
        style: {
          border: `1.5px solid ${color}`,
          color: color,
        },
      }}
      okText="SAVE"
      cancelText="CANCEL"
    >
      <div>
        <h3 style={{ marginTop: "5%", color: color }}>Name:</h3>
        <ModalInput
          color={color}
          placeholder="Full Name"
          onChange={setName}
          value={newName}
          isFilled={newName == ""}
          onPressEnter={onPressEnter}
        ></ModalInput>
      </div>

      <div>
        <h3 style={{ marginTop: "5%", color: color }}>Phone Number:</h3>
        <ConfigProvider locale={en} areaSorter={(a, b) => sortByName(a, b)}>
          <CountryPhoneInput
            autoFocus={editType == "phone" && isModalVisible}
            style={{
              border: `1px solid ${lightenDarkenColor(color, 120)}`,
              color: color,
              fontWeight: 500,
              backgroundColor:
                !("phone" in mobilePhoneNumber) || mobilePhoneNumber["phone"] == "" ? "white" : lightenDarkenColor(color, 140),
            }}
            ref={numberRef}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onPressEnter={() => {
              if (!loading) editInfo();
            }}
            value={mobilePhoneNumber}
            onChange={(v) => {
              setMobilePhoneNumber(v);
            }}
          />
        </ConfigProvider>
      </div>
    </Modal>
  );
}

export { ProfileModal };
