import { createSlice } from "@reduxjs/toolkit";

const loadingSections = [
  {
    index: 0,
    id: 0,
    name: "",
    apps: [],
    style: [],
  },
  {
    index: 1,
    id: 1,
    name: "",
    apps: [],
    style: [],
  },
  {
    index: 2,
    id: 2,
    name: "",
    apps: [],
    style: [],
  },
  {
    index: 3,
    id: 3,
    name: "",
    apps: [],
    style: [],
  },
];

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    filter: {
      sections: JSON.parse(JSON.stringify(loadingSections)),
      value: "",
      defaultTheme: {},
      originalTheme: {},
      colors: {},
      titles: {},
      permissions: null,
    },
  },
  reducers: {
    setSections: (state, action) => {
      if (action.payload.sections != undefined) state.filter.sections = action.payload.sections;
      if (action.payload.defaultTheme != undefined) state.filter.defaultTheme = action.payload.defaultTheme;
      state.value = state.value;
    },
    setNewOrder: (state, action) => {
      var id = action.payload.sectionId;
      var order = action.payload.order;
      var result = [];
      var items = state.filter.sections.filter((section) => section.id == id)[0].apps;
      order.forEach(function (key) {
        items.filter(function (item) {
          if (item.id == key) {
            result.push(item);
          }
        });
      });
      state.filter.sections.filter((section) => section.id == id)[0].apps = result;
    },
    addApp: (state, action) => {
      var id = action.payload.sectionId;
      var app = action.payload.app;
      state.filter.sections.filter((section) => section.id == id)[0].apps.push(app);
      state.value = state.value;
    },
    editApp: (state, action) => {
      var newApp = action.payload.app;
      for (let index in state.filter.sections) {
        var apps = state.filter.sections[index].apps.filter((app) => app.id == newApp.id);
        if (apps.length != 0) {
          var i = state.filter.sections[index].apps.indexOf(apps[0]);
          state.filter.sections[index].apps[i] = newApp;
          break;
        }
      }
      state.value = state.value;
    },
    deleteApp: (state, action) => {
      var oldApp = action.payload.app;
      for (let index in state.filter.sections) {
        var apps = state.filter.sections[index].apps.filter((app) => app.id == oldApp.id);
        if (apps.length != 0) {
          state.filter.sections[index].apps = state.filter.sections[index].apps.filter((app) => app.id != oldApp.id);
          break;
        }
      }
      state.value = state.value;
    },
    addSection: (state, action) => {
      var section = action.payload.section;
      state.filter.sections.push(section);
      state.value = state.value;
    },
    editSection: (state, action) => {
      var id = action.payload.sectionId;
      var name = action.payload.name;
      state.filter.sections.filter((section) => section.id == id)[0].name = name;
      state.value = state.value;
    },
    deleteSection: (state, action) => {
      var id = action.payload.sectionId;
      state.filter.sections = state.filter.sections.filter((section) => section.id != id);
      state.value = state.value;
    },
    setOriginalThemes: (state, action) => {
      state.filter.originalTheme = action.payload.originalTheme;
      state.value = state.value;
    },
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setColor: (state, action) => {
      state.filter.colors[action.payload.id] = action.payload.color;
      state.value = state.value;
    },
    setTitle: (state, action) => {
      state.filter.titles[action.payload.id] = action.payload.title;
      state.value = state.value;
    },
    setDefault: (state, actions) => {
      state.filter.sections = JSON.parse(JSON.stringify(loadingSections));
      state.value = state.value;
    },
    revertThemeChanges: (state, action) => {
      state.filter.defaultTheme = JSON.parse(JSON.stringify(state.filter.originalTheme));
      state.value = state.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSections } = filterSlice.actions;
export const { setNewOrder } = filterSlice.actions;
export const { addApp } = filterSlice.actions;
export const { editApp } = filterSlice.actions;
export const { deleteApp } = filterSlice.actions;
export const { addSection } = filterSlice.actions;
export const { editSection } = filterSlice.actions;
export const { deleteSection } = filterSlice.actions;
export const { setOriginalThemes } = filterSlice.actions;
export const { setValue } = filterSlice.actions;
export const { setColor } = filterSlice.actions;
export const { setTitle } = filterSlice.actions;
export const { revertThemeChanges } = filterSlice.actions;
export const { setDefault } = filterSlice.actions;

export const selectFilter = (state) => getSections(state);
export const selectTheme = (state) => state.filter.filter.defaultTheme;
export const selectOriginalTheme = (state) => state.filter.filter.originalTheme;
export const selectPermissions = (state) => state.filter.value.permissions;
export const isDefault = (state) => isDefaultSection(state);

const isDefaultSection = (state) => {
  return JSON.stringify(state.filter.filter.sections) == JSON.stringify(loadingSections);
};

const getSections = (state) => {
  var sections = JSON.parse(JSON.stringify(state.filter.filter.sections));
  sections = sections.map(function (section) {
    var color = getColor("Section Header", section.style);
    if (color != undefined) {
      section.color = color;
    } else {
      section.color = getColor("Section Header", state.filter.filter.defaultTheme);
    }
    section.apps = section.apps;
    return section;
  });

  if (typeof state.filter && state.filter.value && state.filter.value.length != 0) {
    var filter = state.filter.value;
    var filteredSections = [];
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      if (section.name.toLowerCase().includes(filter.toLowerCase())) {
        filteredSections.push(section);
      } else {
        var filteredApps = [];
        for (var j = 0; j < section.apps.length; j++) {
          var app = section.apps[j];
          if (app.name.toLowerCase().includes(filter.toLowerCase())) {
            filteredApps.push(app);
          } else {
            if (app.tags[0] != undefined) {
              for (var k = 0; k < app.tags.length; k++) {
                var tag = app.tags[k];
                if (tag.toLowerCase().includes(filter.toLowerCase())) {
                  filteredApps.push(app);
                  break;
                }
              }
            }
          }
        }
        if (filteredApps.length != 0) {
          section.apps = filteredApps;
          filteredSections.push(section);
        }
      }
    }
    return filteredSections;
  }
  return sections;
};

export const getColor = (component, theme) => {
  var themes = JSON.parse(JSON.stringify(theme));

  for (let i in themes) {
    if (themes[i].component == component) {
      return themes[i].color;
    }
  }
  return undefined;
};

export { getSections };
export default filterSlice.reducer;
