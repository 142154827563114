import { Tag, Input, message } from "antd";
import { createRef, useEffect, useState } from "react";
import { generateIcon } from "../../iconGenerator";
import {
  generateDashedButtonBackground,
  lightenDarkenColor,
} from "../../utils";

/**
 * App tags used for searching
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function EditableTagGroup(props) {
  const setTags = props.setTags;
  const tags = props.tags;
  const color = props.color;

  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [inputValue, SetInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = createRef(null);

  const handleClose = (removedTag) => {
    var newTags = JSON.parse(JSON.stringify(tags));
    newTags = newTags.filter((tag) => tag != removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setBackgroundColor("transparent");
    setInputVisible(true);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  const handleInputChange = (e) => {
    SetInputValue(e.target.value);
  };

  const handleInputConfirm = (isBlur) => {
    if (inputValue != "") {
      var newTag = JSON.parse(JSON.stringify(inputValue));
      if (tags != undefined && tags.indexOf(newTag) < 0) {
        var newTags = JSON.parse(JSON.stringify(tags));
        newTags.push(newTag);
        setTags(newTags);
        SetInputValue("");
        if (isBlur) setInputVisible(false);
      } else {
        message.destroy();
        message.warning("Tag already exists!");
      }
    } else if (isBlur) setInputVisible(false);
  };

  return (
    <div style={{ display: "flex", verticalAlign: "center" }}>
      {tags.map((tag) => (
        <Tag
          className="edit-tag"
          key={tag}
          closable
          style={{
            backgroundColor: lightenDarkenColor(color, 130),
            color: color,
          }}
          onClose={() => handleClose(tag)}
        >
          {tag}
        </Tag>
      ))}
      {!inputVisible ? (
        <Tag
          onMouseEnter={() =>
            setBackgroundColor(lightenDarkenColor(color, 150))
          }
          onMouseLeave={() => setBackgroundColor("transparent")}
          onClick={showInput}
          className="site-tag-plus"
          style={{ paddingTop: 0, paddingLeft: 0 }}
        >
          {generateDashedButtonBackground(
            color,
            backgroundColor,
            100,
            36,
            "New Tag",
            12,
            "55%",
            "47.3%",
            generateIcon("add", 10, 11.5, color, 15, 11)
          )}
        </Tag>
      ) : (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 78,
            height: 36,
            border: `1px solid ${lightenDarkenColor(color, 140)}`,
          }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => handleInputConfirm(true)}
          onPressEnter={() => handleInputConfirm(false)}
          maxLength={7}
        ></Input>
      )}
    </div>
  );
}

export { EditableTagGroup };
