import { SmileOutlined } from "@ant-design/icons";
import { List, message, Modal, Result, Typography } from "antd";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { EditSectionTitle, GetSections, ReIndexSections } from "../../api/bookmark";
import "../../App.css";
import { selectEditMode } from "../../store/editAppsSlice";
import { editSection, isDefault, selectFilter, setDefault, setSections } from "../../store/filterSlice";
import { SectionModal } from "../Modal/SectionModal";
import { AppSection } from "./AppSection/AppSection";

const { Title } = Typography;

function move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

/**
 * List of draggable sections
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function SectionList(props) {
  const backgroundColor = props.backgroundColor;
  const textColor = props.textColor;
  const setNewSectionModalVisible = props.setNewSectionModalVisible;
  const newSectionModalVisible = props.newSectionModalVisible;
  const modalColor = props.modalColor;
  const scroll = props.scroll;

  const dispatch = useDispatch();
  const sections = useSelector(selectFilter);
  const isLoading = useSelector(isDefault);
  const editMode = useSelector(selectEditMode);

  const [activeSections, setActiveSections] = useState(undefined);
  const [cancelActive, setCancelActive] = useState(true);
  const [draggable, setDraggable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalTitleVisible, setModalTitleVisible] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(isLoading);
  const [updatedId] = useState(undefined);
  const [updatedTitle] = useState(undefined);
  const [user] = useState(props.user);

  useEffect(() => {
    dispatch(setDefault(undefined));
    setSectionLoading(true);
  }, [window.location.pathname]);

  useEffect(() => {
    GetSections(user.email)
      .then((sections) => {
        let payload = { sections: sections };
        dispatch(setSections(payload));
        setSectionLoading(false);
      })
      .catch((error) => {});
  }, [user]);

  const updateColorById = (id, color) => {
    var activeSectionsVar = JSON.parse(JSON.stringify(activeSections));
    for (let index in activeSectionsVar) {
      var section = activeSectionsVar[index];
      if (section.id == id) {
        section.color = color;
        setActiveSections(activeSectionsVar);
        break;
      }
    }
  };

  const setNewTitle = (id, title) => {
    setLoading(true);
    message.loading("Saving new title...", 1000000);
    setCancelActive(false);
    EditSectionTitle(id, title).then(() => {
      message.destroy();
      let payload = { sectionId: id, name: title };
      dispatch(editSection(payload));
      setModalTitleVisible(false);
      setLoading(false);
      setCancelActive(true);
      message.success("Title changed!");
    });
  };

  const saveNewTitle = () => {
    setLoading(true);
    setCancelActive(false);
    EditSectionTitle(updatedId, updatedTitle).then(() => {
      GetSections(user.email)
        .then((sections) => {
          let payload = { sections: sections };
          dispatch(setSections(payload));
          setModalTitleVisible(false);
          setLoading(false);
          setCancelActive(true);
        })
        .catch((error) => {});
    });
  };

  const onDragEnd = (result) => {
    var id = parseInt(result.draggableId);
    var index = result.destination.index;
    var originalIndex = result.source.index;
    var currentSections = JSON.parse(JSON.stringify(activeSections));
    currentSections = move(currentSections, originalIndex, index);
    currentSections[index].index = index;
    currentSections[originalIndex].index = originalIndex;
    var orignialId = currentSections[originalIndex].id;
    var newOrder = [
      { id: id, index: index },
      { id: orignialId, index: originalIndex },
    ];
    if (JSON.stringify(currentSections) != JSON.stringify(activeSections)) {
      setDraggable(false);
      message.loading("Reordering...");
      let payload = { sections: currentSections };
      dispatch(setSections(payload));
      ReIndexSections(newOrder).then(() => {
        setActiveSections(currentSections);
        message.destroy();
        message.success("Section Order Saved!");
        setDraggable(true);
      });
    }
  };

  useEffect(() => {
    setActiveSections(
      sections.sort(function (a, b) {
        return a.index - b.index;
      })
    );
  }, [sections]);

  return (
    <DragDropContext onDragUpdate={() => {}} onDragEnd={(result) => onDragEnd(result)} style={{ flex: 1 }}>
      <Droppable droppableId={`id-${user.email}`}>
        {(provided) => (
          <div style={{ flex: 1 }} ref={provided.innerRef}>
            {/* style={getListStyle(snapshot.isDraggingOver)}> */}
            <List
              locale={{
                emptyText: (
                  <Result
                    style={{
                      opacity: 0.7,
                      backgroundColor: textColor,
                      borderRadius: "20px",
                      margin: "4% auto auto auto",
                      maxWidth: "1000px",
                    }}
                    icon={<SmileOutlined style={{ color: backgroundColor }} />}
                    title={
                      <Title level={3} style={{ color: backgroundColor }}>
                        Welcome to MyBoard, {user.name}!
                      </Title>
                    }
                    subTitle="It seems you don't have any sections. Turn on the edit mode on the top right corner to start working!"
                  />
                ),
              }}
              dataSource={activeSections}
              renderItem={(section, index) => (
                <Draggable
                  key={`draggable-${section.id}`}
                  draggableId={section.id.toString()}
                  index={index}
                  isDragDisabled={!editMode || !draggable}
                >
                  {(provided) => (
                    <div
                      key={`draggable-div-${section.id}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="expand"
                      index={"div" + index}
                    >
                      <List.Item key={`LIST-ITEM-${section.id}`} style={{ width: "100%", paddingBottom: "20px" }}>
                        <div style={{ width: "100%" }}>
                          <AppSection
                            editMode={editMode}
                            defaultLogos={props.defaultLogos}
                            draggable={draggable}
                            setDraggable={setDraggable}
                            index={"section" + index.toString()}
                            key={"section" + index.toString()}
                            user={user}
                            section={section}
                            updateColorById={updateColorById}
                            setNewTitle={setNewTitle}
                            loading={sectionLoading}
                          />
                          <Modal
                            index={"title_modal" + index}
                            title="Saving Alert"
                            centered
                            visible={modalTitleVisible}
                            okText="Save"
                            closable={cancelActive}
                            okButtonProps={{ loading: loading }}
                            cancelButtonProps={{ disabled: !cancelActive }}
                            onOk={() => saveNewTitle()}
                            onCancel={() => setModalTitleVisible(false)}
                          >
                            Are you sure you want to change the title of the section?
                          </Modal>
                        </div>
                      </List.Item>
                    </div>
                  )}
                </Draggable>
              )}
            ></List>
            <SectionModal
              color={modalColor}
              user={user}
              isModalVisible={newSectionModalVisible}
              setIsModalVisible={setNewSectionModalVisible}
              onEnd={scroll}
            ></SectionModal>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
  // }
}

export { SectionList };
