import { Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { createRef, useEffect, useState } from "react";
import { lightenDarkenColor } from "../../utils";

/**
 * Modal with on press enter functionality
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function CustomModal(props) {
  const id = props.id;
  const confirmDelete = props.onOk;
  const innerText = props.innerText;
  const color = props.color;

  const [cancelButtonProps, setCancelButtonProps] = useState({
    style: { border: `1.5px solid ${color}`, color: color },
  });

  const [loading, setLoading] = useState(false);

  const ref = createRef();

  useEffect(() => {
    setLoading(props.loading);
    if (props.visible) ref.current.focus();
    if (props.cancelButtonProps) {
      var newProps = Object.assign(
        JSON.parse(JSON.stringify(props.cancelButtonProps)),
        { style: { border: `1.5px solid ${color}`, color: color } }
      );
      setCancelButtonProps(newProps);
    }
  }, [props]);

  return (
    <Modal
      style={{
        borderRadius: "8px",
        border: `1px solid ${lightenDarkenColor(color, 25)}`,
      }}
      okButtonProps={{
        id: `modaldelete${id}`,
        loading: props.loading,

        style: {
          backgroundColor: color,
          color: "white",
          border: `0px`,
        },
      }}
      okText="YES"
      cancelText="CANCEL"
      {...props}
      cancelButtonProps={cancelButtonProps}
    >
      {innerText}
      <div style={{ opacity: "0", height: "0px" }}>
        <Input
          ref={ref}
          onPressEnter={() => {
            if (!loading) confirmDelete();
          }}
          bordered={false}
        ></Input>
      </div>
    </Modal>
  );
}

export { CustomModal };
