import { PoweroffOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Dropdown, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { FetchNotifications } from "../../api/notifications";
import { themeColors } from "../../colors";
import { generateIcon, generateLogo } from "../../iconGenerator";
import { selectTheme } from "../../store/filterSlice";
import { lightenDarkenColor } from "../../utils";
import EditAppsToggle from "../utils/EditAppsToggle";
import { NotificationList } from "./Notifications/NotificationList";

const MINUTE_MS = 10000;

/**
 * Nav bar when logged in
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function TopBar(props) {
  const logout = props.logout;
  const user = props.user;

  let location = useLocation();

  const [color, setColor] = useState(themeColors["Default"][0]);
  const [iconColor, setIconColor] = useState(
    typeof themeColors["Default"][1] == "string" ? themeColors["Default"][1] : themeColors["Default"][1][0]
  );
  const [maxCount, setMaxCount] = useState(5);
  const [notifications, setNotifications] = useState([]);
  const [textColor, setTextColor] = useState(themeColors["Default"][0]);
  const [menuItemColor, setItemColor] = useState(themeColors["Default"][0]);
  const [userBackgroundColor, setUserBackgroundColor] = useState(themeColors["Default"][0]);
  const [isAppsPage, setIsAppsPage] = useState(false);

  const theme = useSelector(selectTheme);

  const isSmall = useMediaQuery({ query: "(max-width:712px)" });

  useEffect(() => {
    for (var i in theme) {
      if (theme[i].component == "Nav Bar") {
        setColor(themeColors[theme[i].color][0]);
        setUserBackgroundColor(themeColors[theme[i].color][0]);
        setItemColor(themeColors[theme[i].color][3]);
      } else if (theme[i].component == "Background")
        setIconColor(
          typeof themeColors[theme[i].color][1] == "string" ? themeColors[theme[i].color][1] : themeColors[theme[i].color][1][0]
        );
      else if (theme[i].component == "Section Header") setTextColor(themeColors[theme[i].color][2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const handleLogout = () => {
    logout();
  };

  const markAsRead = () => {
    setMaxCount(5);
  };

  useEffect(() => {
    FetchNotifications(user.user.email).then((notifications) => {
      setNotifications(notifications["notifications"]);
    });
  }, [MINUTE_MS]);

  useEffect(() => {
    const interval = setInterval(() => {
      FetchNotifications(user.user.email).then((notifications) => {
        setNotifications(notifications["notifications"]);
      });
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsAppsPage(location.pathname === "/" || location.pathname.match(/users\/.+/));
  }, [location]);

  const nameStyle = {
    fontStyle: "normal",
    color: iconColor,
    textOverflow: "ellipsis-word",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: "8px",
  };

  const userMenu = (
    <Menu
      style={{
        marginTop: "10px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 400,
      }}
    >
      <Menu.Item
        icon={generateIcon("userSettings", 20, 20, menuItemColor)}
        style={{ color: menuItemColor }}
        key="00"
        onClick={() => setUserBackgroundColor(color)}
      >
        <Link to="/preferences">Preferences</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => setUserBackgroundColor(color)}
        style={{ color: menuItemColor }}
        icon={generateIcon("help", 20, 20, menuItemColor)}
        key="03"
      >
        <Link to="/help">Help</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="04"
        danger
        icon={<PoweroffOutlined style={{ marginLeft: "3px" }} />}
        onClick={() => {
          setUserBackgroundColor(color);
          handleLogout();
        }}
      >
        <a target="_blank">Log Out</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ backgroundColor: color }}>
      <Row
        justify="middle"
        style={{
          margin: "auto",
          width: "100%",
          backgroundColor: color,
          height: "60px",
        }}
      >
        <Col flex="12px"></Col>
        <Col flex={`${CalculateWidth(isSmall)}px`}>
          <Link to="/">{generateLogo(iconColor, CalculateWidth(isSmall), "40", CalculateWidth(isSmall), "40", isSmall)}</Link>
        </Col>
        <Col
          flex="auto"
          style={{
            color: textColor,
            fontSize: "18px",
            marginRight: "2px",
            float: "right",
          }}
        ></Col>
        {isAppsPage && (
          <Col>
            <EditAppsToggle />
          </Col>
        )}
        {user.user.role == "Admin" ? (
          <Col flex="54px">
            <Link to="/users">
              <Button
                type="link"
                icon={generateIcon(location.pathname.includes("/users") ? "manageUsersOutlined" : "manageUsers", "19", "22", iconColor)}
              />
            </Link>
          </Col>
        ) : (
          <></>
        )}
        <Col flex="54px">
          <Dropdown
            placement="bottomRight"
            onVisibleChange={markAsRead}
            overlay={
              <NotificationList
                color={menuItemColor}
                maxCount={maxCount}
                setMaxCount={setMaxCount}
                notifications={notifications}
                setNotifications={setNotifications}
                email={user.user.email}
              ></NotificationList>
            }
          >
            <Badge
              count={
                notifications.filter(function (notification) {
                  return !notification.read;
                }).length
              }
              overflowCount={10}
            >
              <Button type="link" icon={generateIcon("notifications", "20", "21", iconColor)} />
            </Badge>
          </Dropdown>
        </Col>
        {user.user.role == "Admin" ? (
          <Col flex="54px">
            <Link to="/message">
              <Button
                type="link"
                icon={generateIcon(location.pathname.includes("/message") ? "messageFilled" : "message", "20", "20", iconColor)}
              />
            </Link>
          </Col>
        ) : (
          <></>
        )}
        <Col flex={`${(!isSmall ? user.user.name.length * 10 : 0) + 60}px`}>
          <Dropdown
            placement="bottomRight"
            trigger={["hover"]}
            overlay={userMenu}
            onVisibleChange={(value) => {
              setUserBackgroundColor(value ? lightenDarkenColor(color, 20) : color);
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                backgroundColor: userBackgroundColor,
                height: "50px",
                paddingTop: "3px",
                transition: "background-color 0.4s linear",
              }}
            >
              <Avatar size={40} src={user.user.image}></Avatar>
              <div className="welcome-visible" style={nameStyle}>
                {user.user.name}
              </div>
              <div
                style={{
                  margin: "auto auto auto 8px",
                  display: "inline-block",
                }}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
              >
                {generateIcon("down", "8", "7", iconColor)}
              </div>
            </div>
          </Dropdown>

          {/* <Button
            type="link"
            icon={generateIcon("manageUsersOutlined", "19", "22", iconColor)}
          /> */}
        </Col>
        <Col flex={"12px"}></Col>
      </Row>
    </div>
  );
}

export { TopBar };

function CalculateWidth(isSmall) {
  return !isSmall ? "160" : "50";
}
