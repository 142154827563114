import { message, Typography } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useMemo, useState } from "react";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-drag";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReIndex } from "../../../../api/bookmark";
import { generateIcon } from "../../../../iconGenerator";
import { selectEditMode } from "../../../../store/editAppsSlice";
import { setNewOrder } from "../../../../store/filterSlice";
import { generateDashedButtonBackground, lightenDarkenColor } from "../../../../utils";
import { AppModal } from "../../../Modal/AppModal";
import { AppIcon } from "./AppIcon";

const { Text } = Typography;

function getArraysIntersection(arr1, arr2) {
  return arr1.filter((item1) => arr2.some((item2) => item1.id === item2.id));
}

/**
 * Draggable grid of app buttons that is in the app section
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */

function AppGrid(props) {
  const addable = props.addable;
  const defaultLogos = props.defaultLogos;
  const loading = props.loading;
  const sectionId = props.sectionId;
  const setAddable = props.setAddable;
  const user = props.user;
  const color = props.color;

  const [apps, setApps] = useState(props.apps);
  const [editting, setEditting] = useState(false);
  const [order, setOrder] = useState(undefined);
  const [addAppBackGroundColor, SetAddAppBackGroundColor] = useState("transparent");

  const dispatch = useDispatch();
  const deletable = useSelector(selectEditMode);

  const xxl = useMediaQuery({ query: "(min-width:1600px)" });
  const xl = useMediaQuery({ query: "(min-width:1200px)" });
  const lg = useMediaQuery({ query: "(min-width:992px)" });
  const md = useMediaQuery({ query: "(min-width:768px)" });
  const sm = useMediaQuery({ query: "(min-width:576px)" });

  const appNumberPerRow = useMemo(() => {
    return xxl ? 10 : xl ? 10 : lg ? 8 : md ? 6 : sm ? 4 : 3;
  }, [xxl, xl, lg, md, sm]);

  useEffect(() => {
    let newApps = props.apps;
    if (order != undefined) {
      let indexedApps = getArraysIntersection(apps, props.apps);
      let unIndexedApps = props.apps.filter((app) => !order.includes(app.id));
      newApps = indexedApps.concat(unIndexedApps);
    }
    setApps(newApps);
  }, [props.apps]);

  useEffect(() => {}, [editting]);

  const addApps = (app) => {
    setApps(apps.concat(app));
  };

  useEffect(() => {
    if (JSON.stringify(apps) != JSON.stringify(props.apps)) {
      setOrder(apps.map((item) => item.id));
    }
  }, [apps]);

  useEffect(() => {
    reOrder();
  }, [order]);

  const reOrder = () => {
    if (order != undefined) {
      message.loading("Reordering...", 1000000);
      setEditting(true);
      let oldOrder = apps.map((app) => app.id.toString());
      if (JSON.stringify(oldOrder) != JSON.stringify(order)) {
        let newOrder = order.map((id, index) => {
          return { id: parseInt(id), index: index + 1 };
        });
        ReIndex(newOrder).then(() => {
          dispatch(setNewOrder({ sectionId: sectionId, order: order }));
          setOrder(undefined);
          message.destroy();
          setEditting(false);
          message.success("App Order Saved!");
        });
      }
    }
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(apps, sourceIndex, targetIndex);
    setApps(nextState);
  }

  return (
    <div>
      <GridContextProvider onChange={onChange}>
        {apps.length == 0 && !deletable ? (
          !loading ? (
            <Text type="secondary" italic>
              No apps available in this section
            </Text>
          ) : (
            <div style={{ height: 110 }}></div>
          )
        ) : (
          <GridDropZone
            id={`${sectionId}-apps`}
            boxesPerRow={appNumberPerRow}
            rowHeight={135}
            disableDrag={!deletable || editting}
            style={{
              height: Math.ceil((apps.length + (deletable ? 1 : 0)) / appNumberPerRow) * 135,
            }}
          >
            {apps.map((item) => (
              <GridItem key={item.id}>
                <div className="grid-item">
                  <AppIcon
                    setEditting={setEditting}
                    deletable={deletable}
                    app={item}
                    color={color}
                    email={user.email}
                    defaultLogos={defaultLogos}
                  ></AppIcon>
                </div>
              </GridItem>
            ))}
            {deletable ? (
              <GridItem id={`add-${sectionId}`}>
                <div
                  onMouseEnter={() => {
                    SetAddAppBackGroundColor(lightenDarkenColor(color, 160));
                  }}
                  onMouseLeave={() => {
                    SetAddAppBackGroundColor("transparent");
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => {
                    setAddable(true);
                    // setEditting(true);
                  }}
                  style={{
                    padding: 10,
                    display: "flex",
                    animation: "fadeIn 0.7s ease-in-out",
                    transition: "background-color 0.5s linear",
                    cursor: "pointer",
                  }}
                >
                  {generateDashedButtonBackground(
                    color,
                    addAppBackGroundColor,
                    100,
                    122,
                    "Add App",
                    14,
                    "50%",
                    84,
                    generateIcon("bigAdd", 200, 200, color, 36, 30)
                  )}
                </div>
              </GridItem>
            ) : (
              <></>
            )}
          </GridDropZone>
        )}
      </GridContextProvider>
      <AppModal
        defaultLogos={defaultLogos}
        color={color}
        addApps={addApps}
        user={user}
        id={sectionId}
        isModalVisible={addable}
        setIsModalVisible={setAddable}
      ></AppModal>
    </div>
  );
}

export { AppGrid };
