import "antd/dist/antd.css";
import { Row, Radio, Col } from "antd";
import { themeColors } from "../../../colors";
import { ThemeIcon } from "./ThemeIcon";
import { useEffect, useState } from "react";

/**
 * Grid of themes that are selected on personalization
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function ThemeGrid(props) {
  const setDefaultTheme = props.setDefaultTheme;

  const [defaultTheme, setTheme] = useState(props.defaultTheme);
  const [value, setValue] = useState(defaultTheme["Nav Bar"].color);

  useEffect(() => {
    setTheme(props.defaultTheme);
  }, [props.defaultTheme]);

  useEffect(() => {
    setValue(defaultTheme["Nav Bar"].color);
  }, [defaultTheme]);

  const onChange = (e) => {
    var theme = JSON.parse(JSON.stringify(defaultTheme));
    var color = e.target.value;
    theme["Nav Bar"].color = color;
    theme["Section Background"].color = color;
    theme["Section Header"].color = color;
    theme["Background"].color = color;
    setDefaultTheme(theme);
    setValue(e.target.value);
  };

  return (
    <Radio.Group
      onChange={onChange}
      style={{ display: "initial" }}
      value={value}
      disabled={props.loading}
      buttonStyle="solid"
    >
      <Row justify="start" gutter={[0, 30]}>
        {Object.keys(themeColors).map((key, index) => (
          <Col key={key} lg={4} md={4} s={7} xs={7}>
            <Radio value={key} key={key}>
              <div
                style={{
                  height: "137px",
                  width: "97px",
                  borderRadius: "16px",
                  border:
                    key == value ? `1px solid ${themeColors[value][3]}` : `0px`,
                }}
              >
                <ThemeIcon
                  topBarColor={themeColors[key][0]}
                  backgroundColor={themeColors[key][1]}
                  cardColor={themeColors[key][2]}
                  isGradient={themeColors[key][4]}
                ></ThemeIcon>
                <div style={{ marginTop: "14px" }}>{key}</div>
              </div>
            </Radio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
}

export { ThemeGrid };
