import { Collapse } from "antd";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AddSectionPanel } from "../components/Sections/AddSectionPanel";
import { SectionList } from "../components/Sections/SectionList";
import { SearchBar } from "../components/utils/SearchBar";
import { selectEditMode } from "../store/editAppsSlice";

const { Panel } = Collapse;

/**
 * Page containing sections and their edit functionalities (main page)
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function SectionPage(props) {
  const backgroundColor = props.backgroundColor;
  const fetchedUser = props.fetchedUser;
  const textColor = props.textColor;
  const borderColor = props.borderColor;

  const messagesEndRef = useRef(null);

  const editMode = useSelector(selectEditMode);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const [newSectionModalVisible, setNewSectionModalVisible] = useState(false);

  return (
    <div
      ref={messagesEndRef}
      style={{
        display: "flex",
        flexFlow: "column",
        alignContent: "center",
        height: "100%",
        flex: 1,
      }}
    >
      <SearchBar />

      <div
        style={{
          display: "flex",
          flexFlow: "column",
          margin: "0px 10px 0px 10px",
          alignContent: "center",
          height: "100%",
          flex: 1,
        }}
      >
        <AddSectionPanel setNewSectionModalVisible={setNewSectionModalVisible} borderColor={borderColor} editMode={editMode} />
        <SectionList
          defaultLogos={props.defaultLogos}
          newSectionModalVisible={newSectionModalVisible}
          setNewSectionModalVisible={setNewSectionModalVisible}
          scroll={scrollToBottom}
          modalColor={borderColor}
          user={fetchedUser.user}
          textColor={textColor}
          backgroundColor={backgroundColor}
          style={{
            display: "flex",
            flexFlow: "column",
            width: "100%",
            height: "100%",
            flex: 1,
          }}
        />
      </div>
    </div>
  );
}

export { SectionPage };
