import "antd/dist/antd.css";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
import { message, Select } from "antd";
import { SendSingleNotification } from "../../api/notifications";
import { lightenDarkenColor } from "../../utils";

/**
 * The grid containing all color buttons.
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function MessageModal(props) {
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const user = props.user;
  const color = props.color;

  const [loading, setLoading] = useState(false);
  const [mes, setMessage] = useState("");

  const clearFields = () => {
    setMessage("");
  };

  const sendMessage = () => {
    if (mes == undefined || mes == "" || mes.replace(/\s/g, "") == "") {
      message.destroy();
      message.error(`Message box empty!`);
    } else {
      setLoading(true);
      SendSingleNotification(user.id.toString(), mes).then((res) => {
        setIsModalVisible(false);
        setLoading(false);
        clearFields();
        message.success(`Message sent to ${user.name}`);
      });
    }
  };

  return (
    <Modal
      centered
      visible={isModalVisible}
      okButtonProps={{
        loading: loading,
        style: { backgroundColor: color, color: "white" },
        children: "SAVE",
      }}
      cancelButtonProps={{
        style: {
          border: `1.5px solid ${color}`,
          children: "CANCEL",
          color: color,
        },
      }}
      width={"60%"}
      onOk={sendMessage}
      style={{ minWidth: "280px", width: "70%" }}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
    >
      <div style={{ padding: "1%", color: color }}>
        <h3 style={{ marginTop: "2%" }}>To:</h3>
        <Select
          mode="multiple"
          disabled
          style={{
            width: "100%",
            borderRadius: "20px",
            border: `1px solid ${lightenDarkenColor(color, 120)}`,
            color: color,
          }}
          placeholder="Please select"
          value={[user.name]}
        ></Select>
        <h3 style={{ marginTop: "2%", color: color }}>Message:</h3>
        <TextArea
          value={mes}
          onPressEnter={(event) => {
            if (!loading) sendMessage();
            if (mes.replace(/(\r\n|\n|\r|)/gm, "").replace(" ", "") == "")
              event.preventDefault();
          }}
          rows={10}
          style={{
            borderRadius: "8px",
            border: `1px solid ${lightenDarkenColor(color, 120)}`,
          }}
          onChange={(value) => setMessage(value.target.value)}
        ></TextArea>
      </div>
    </Modal>
  );
}

export { MessageModal };
