import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { generateIcon } from "../../iconGenerator";

/**
 * Breadcrumb to navigate between pages
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
const BreadCrumb = (props) => {
  const location = useLocation();
  const color = props.color;

  const breadCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    return (
      <div style={{ marginLeft: "12px", marginTop: "24px", align: "left" }}>
        {pathnames.length > 0 ? (
          <Breadcrumb
            style={{
              textAlign: "left",
              fontSize: "14px",
              color: color,
              fontWeight: 400,
            }}
            separator={generateIcon("next", "4", "8", color)}
          >
            <Breadcrumb.Item style={{ textAlign: "bottom" }}>
              <Link to="/">
                <div
                  style={{
                    display: "inline-block",
                    color: color,
                    fontWeight: 400,
                  }}
                >
                  Home
                </div>
              </Link>
            </Breadcrumb.Item>
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index == pathnames.length - 1;
              return isLast ? (
                <Breadcrumb.Item key={name}>
                  <div
                    style={{
                      display: "inline-block",
                      color: color,
                      fontWeight: 400,
                    }}
                  >
                    {capatilize(name)}
                  </div>
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item key={index} style={{ color: color }}>
                  <Link to={`${routeTo}`}>
                    <div style={{ display: "inline-block", color: color }}>
                      {capatilize(name)}
                    </div>
                  </Link>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        ) : (
          <div />
        )}
      </div>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
