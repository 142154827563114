import "antd/dist/antd.css";
import { Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { CreateSection } from "../../api/bookmark";
import { addSection } from "../../store/filterSlice";
import { useDispatch } from "react-redux";

/**
 * Modal to create and edit sections
 *
 * @version 1.0.1
 * @author [Hafez Jawhary]
 */
function SectionModal(props) {
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const user = props.user;
  const color = props.color;
  const onEnd = props.onEnd;

  const [loading, setLoading] = useState(false);
  const [newName, setName] = useState(
    props.name !== undefined ? props.name : ""
  );
  const [type] = useState("personal");

  const dispatch = useDispatch();

  const clearFields = () => {
    setName(props.name !== undefined ? props.name : "");
  };

  const addNewSection = () => {
    setLoading(true);
    CreateSection(user.email, newName, type == "default" ? "True" : "").then(
      (res) => {
        var section = {};
        section.id = res.id;
        section.name = newName;
        section.index = res.index;
        section.apps = [];
        section.style = [];
        dispatch(addSection({ section: section }));
        setLoading(false);
        setIsModalVisible(false);
        onEnd();
        clearFields();
        message.success("New Section Added!");
      }
    );
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={addNewSection}
      style={{
        border: `1px solid ${color}`,
        borderRadius: "8px",
      }}
      bodyStyle={{ paddingBottom: "40px", paddingTop: "10px" }}
      okButtonProps={{
        loading: loading,
        style: { backgroundColor: color, color: "white" },
      }}
      cancelButtonProps={{
        style: {
          border: `1.5px solid ${color}`,
          color: color,
        },
      }}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
      okText="SAVE"
      cancelText="CANCEL"
    >
      <h3
        style={{
          marginTop: "5%",
          color: color,
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Add Section
      </h3>
      <h3
        style={{
          marginTop: "5%",
          color: color,
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        Name:
      </h3>
      <Input
        placeholder="Section Name"
        allowClear
        onPressEnter={() => {
          if (!loading) addNewSection();
        }}
        onChange={(name) => setName(name.target.value)}
        value={newName}
      ></Input>
    </Modal>
  );
}

export { SectionModal };
