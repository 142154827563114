import { message, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { EditUser } from "../../api/user";
import { generateIcon } from "../../iconGenerator";

const { Dragger } = Upload;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function ImageModal(props) {
  const isModalVisible = props.isModalVisible;
  const setIsModalVisible = props.setIsModalVisible;
  const setUser = props.setUser;
  const user = props.user;
  const color = props.color;

  const [fileList, setFileList] = useState([]);
  const [imageData, setImageData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const clearFields = () => {
    setImageData(undefined);
    setFileList([]);
  };

  const request = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChange = (info) => {
    const { status } = info.file;

    if (info.fileList.length > 0) {
      if (status !== "uploading") {
        message.loading("file uploading...", 1000000);
      }
      setFileList(info.fileList);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageData(e.target.result);
        message.destroy();
      };
      reader.readAsDataURL(info.file.originFileObj);
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.destroy();
        message.error(`${info.file.name} file upload failed.`);
      }
    } else {
      setFileList([]);
    }
  };

  const uploadImage = () => {
    setLoading(true);
    var newUser = JSON.parse(JSON.stringify(user));
    newUser.image = imageData;
    EditUser(newUser).then((res) => {
      newUser.image = res.image;
      setUser(newUser);
      setLoading(false);
      clearFields();
      message.success("Image changed successfully!");
      setIsModalVisible(false);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.originFileObj);
    }
  };

  const onDrop = (e) => {
    if (e.dataTransfer.files.length > 0) setFileList([e.dataTransfer.files[0]]);
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageData(e.target.result);
    };
  };

  return (
    <Modal
      visible={isModalVisible}
      bodyStyle={{ padding: "5% 5% 5% 5%" }}
      onCancel={() => {
        clearFields();
        setIsModalVisible(false);
      }}
      onOk={uploadImage}
      okButtonProps={{
        loading: loading,
        disabled: imageData == undefined,
        style: { backgroundColor: color, color: "white" },
        children: "SAVE",
      }}
      cancelButtonProps={{
        style: {
          border: `1.5px solid ${color}`,
          children: "CANCEL",
          color: color,
        },
      }}
    >
      <h3
        style={{
          color: color,
          paddingBottom: "2%",
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Edit Photo
      </h3>
      <Dragger
        accept=".jpg, .jpeg, .png, .svg, .gif"
        multiple={true}
        fileList={fileList}
        listType="picture"
        preview={true}
        onPreview={handlePreview}
        customRequest={request}
        style={{ padding: "1%", height: "380px" }}
        action=""
        onDrop={onDrop}
        maxCount={1}
        showUploadList={true}
        onChange={onChange}
      >
        <div style={{ display: "block", margin: "auto" }}>
          {generateIcon("cameraBig", 60, 60, color, "50%", "50%")}
        </div>

        <p
          className="ant-upload-text"
          style={{ color: color, fontWeight: 600, fontSize: 18 }}
        >
          <p style={{ fontWeight: 700, display: "inline-block" }}>Browse</p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 14,
              display: "inline-block",
              marginLeft: "6px",
            }}
          >
            {" "}
            or Drag & Drop
          </p>
        </p>
      </Dragger>
    </Modal>
  );
}

export { ImageModal };
